export * from "ce/sagas/tenantSagas";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
  ReduxAction,
} from "@appsmith/constants/ReduxActionConstants";
import {
  fetchCurrentTenantConfigSaga,
  updateTenantConfigSaga,
} from "ce/sagas/tenantSagas";
import TenantApi, {
  UpdateCurrentTenantConfigRequest,
} from "@appsmith/api/TenantApi";
import { all, takeLatest, put, call } from "redux-saga/effects";
import { validateResponse } from "sagas/ErrorSagas";
import type { ApiResponse } from "api/ApiResponses";
import { toast } from "design-system";

export function* updateCurrentTenantConfigSaga(
  action: ReduxAction<UpdateCurrentTenantConfigRequest>,
) {
  try {
    const response: ApiResponse = yield call(
      TenantApi.updateCurrentTenantConfig,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse && response.data) {
      yield put({
        type: ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG_SUCCESS,
        payload: response.data,
      });
      toast.show("自定义品牌保存成功", {
        kind: "success",
      });
    } else {
      yield put({
        type: ReduxActionErrorTypes.UPDATE_CURRENT_TENANT_CONFIG_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_CURRENT_TENANT_CONFIG_ERROR,
    });
  }
}

export default function* tenantSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_CURRENT_TENANT_CONFIG,
      fetchCurrentTenantConfigSaga,
    ),
    takeLatest(ReduxActionTypes.UPDATE_TENANT_CONFIG, updateTenantConfigSaga),
    takeLatest(
      ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG,
      updateCurrentTenantConfigSaga,
    ),
  ]);
}
