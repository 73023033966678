import React, { useRef, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { InputGroup, Classes } from "@blueprintjs/core";
import { AppTheme } from "entities/AppTheming";
import { Colors } from "constants/Colors";
import { debounce, set as _set, cloneDeep } from "lodash";

interface ThemeColorControlProps {
  theme: AppTheme;
  updateTheme: (theme: AppTheme) => void;
}

const StyledInputGroup = styled(InputGroup)`
  .${Classes.INPUT} {
    box-shadow: none;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }
  &&& input {
    padding-left: ${(props) => (props.value ? "5px" : "36px")};
    height: ${(props) => (props.value ? "80px" : "36px")};
    border: 1px solid ${Colors.GREY_5};
    background: ${(props) =>
      props.value
        ? `url('${props.value}') no-repeat fixed center`
        : props.theme.colors.propertyPane.multiDropdownBoxHoverBg};
    color: ${(props) => props.theme.colors.propertyPane.label};

    &:focus {
      border: 1px solid ${Colors.GREY_9};
    }
  }
`;

function ThemeBackgroundUrlControl(props: ThemeColorControlProps) {
  const { theme, updateTheme } = props;
  const userDefinedBgUrl = theme.properties.colors.backgroundUrl;
  const [bgUrl, setBgUrl] = useState<string>(userDefinedBgUrl || "");

  const updateBg = (value: any) => {
    const temp = cloneDeep(theme);
    _set(temp, "properties.colors.backgroundUrl", value);
    updateTheme(temp);
  };

  const debouncedOnChange = useCallback(debounce(updateBg, 400), [
    updateTheme,
    theme,
  ]);

  useEffect(() => {
    setBgUrl(userDefinedBgUrl);
  }, [userDefinedBgUrl]);

  const handleChangeColor = (e: any) => {
    setBgUrl(e.target.value);
    debouncedOnChange(e.target.value);
  };

  return (
    <div className="space-y-2">
      <StyledInputGroup
        autoFocus={false}
        onChange={handleChangeColor}
        placeholder="背景图片"
        value={bgUrl}
      />
    </div>
  );
}

export default ThemeBackgroundUrlControl;
