export * from "ce/pages/AdminSettings/config/types";
import { SettingCategories as SettingCategoriesCE } from "ce/pages/AdminSettings/config/types";

export const SettingCategories = {
  ...SettingCategoriesCE,
  OIDC_AUTH: "oidc",
  USERS: "users",
  GROUPS: "groups",
  ROLES: "roles",
};
