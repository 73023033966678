export * from "ce/utils/adminSettingsHelpers";
import { saveAllowed as saveAllowedCE } from "ce/utils/adminSettingsHelpers";
import type { AdminConfigType } from "@appsmith/pages/AdminSettings/config/types";

export const saveAllowed = (
  settings: any,
  isFormLoginEnabled: boolean,
  socialLoginList: string[],
) => {
  const checkOIDCAuth =
    settings["APPSMITH_OAUTH2_OIDC_CLIENT_ID"] !== "" &&
    socialLoginList.includes("oidc");
  const connectedMethodsCount =
    socialLoginList.length + (isFormLoginEnabled ? 1 : 0);
  if (connectedMethodsCount === 1) {
    return (
      saveAllowedCE(settings, isFormLoginEnabled, socialLoginList) ||
      checkOIDCAuth
    );
  } else {
    return connectedMethodsCount >= 2;
  }
};

// whose component is a router
const routerCategories = ["users", "groups", "roles"];
export const getWrapperCategory = (
  categories: Record<string, AdminConfigType>,
  subCategory: string,
  category: string,
) => {
  if (routerCategories.includes(category)) {
    return categories[category];
  }

  return categories[subCategory || category];
};
