import type { PageErrorMessageProps } from "pages/common/ErrorPages/Components/PageErrorMessage";

export function createMessage(
  format: (...strArgs: any[]) => string,
  ...args: any[]
) {
  return format(...args);
}

/*
  For self hosted CE, it displays the string "Appsmith Community v1.10.0".
 */
export const APPSMITH_DISPLAY_VERSION = (edition: string, version: string) =>
  `Appsmith ${edition} ${version}`;
export const INTERCOM_CONSENT_MESSAGE = () =>
  `请留下邮箱方便我们更好的为您服务`;
export const YES = () => `是的`;
export const ARE_YOU_SURE = () => `确定吗？`;
export const ERROR_ADD_API_INVALID_URL = () =>
  `创建 API 失败！请给你的数据源添加地址`;
export const ERROR_MESSAGE_NAME_EMPTY = () => `请选择一个名字`;
export const ERROR_MESSAGE_CREATE_APPLICATION = () => `应用创建失败`;
export const APPLICATION_NAME_UPDATE = () => `应用名称已更新`;
export const ERROR_EMPTY_APPLICATION_NAME = () => `应用名称不能为空`;
export const API_PATH_START_WITH_SLASH_ERROR = () => `路径不能以 / 开头`;
export const FIELD_REQUIRED_ERROR = () => `必填字段`;
export const INPUT_DEFAULT_TEXT_MAX_CHAR_ERROR = (max: number) =>
  `默认字符最大长度为 ${max}`;
export const INPUT_TEXT_MAX_CHAR_ERROR = (max: number) =>
  `文本长度必须小于 ${max}`;
export const INPUT_DEFAULT_TEXT_MAX_NUM_ERROR = () => `默认文本长度超出最大值`;
export const INPUT_DEFAULT_TEXT_MIN_NUM_ERROR = () => `默认文本长度超出最小值`;
export const INPUT_INVALID_TYPE_ERROR = () => `类型不匹配，请输入合法值`;
export const VALID_FUNCTION_NAME_ERROR = () => `函数名不符合规范（驼峰式）`;
export const UNIQUE_NAME_ERROR = () => `名字必须唯一`;
export const NAME_SPACE_ERROR = () => `名字中间不能包含空格`;
export const APLHANUMERIC_HYPHEN_SLASH_SPACE_ERROR = () =>
  `名字只能包含字母、数字、连字符（-）、斜杠（/）和空格`;

export const FORM_VALIDATION_EMPTY_EMAIL = () => `请填写邮箱地址`;
export const FORM_VALIDATION_INVALID_EMAIL = () => `请填写有效的邮箱地址`;
export const FORM_VALIDATION_INVALID_CAPTCHA = () => `请填写有效的验证码`;

export const ENTER_VIDEO_URL = () => `请填写有效的地址`;
export const ENTER_AUDIO_URL = () => `请填写有效的地址`;

export const FORM_VALIDATION_EMPTY_PASSWORD = () => `请输入密码`;
export const FORM_VALIDATION_PASSWORD_RULE = () => `密码必须是 6 ~ 256 个字符`;
export const FORM_VALIDATION_INVALID_PASSWORD = FORM_VALIDATION_PASSWORD_RULE;

export const LOGIN_PAGE_SUBTITLE = () => `使用你的团队邮箱`;
export const LOGIN_PAGE_TITLE = () => `登录你的账号`;
export const LOGIN_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const LOGIN_PAGE_PASSWORD_INPUT_LABEL = () => `密码`;
export const LOGIN_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `请输入邮箱地址`;
export const LOGIN_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `请输入密码`;
export const LOGIN_PAGE_CAPTCHA_INPUT_PLACEHOLDER = () => `请输入验证码`;
export const LOGIN_PAGE_REFRESH_CAPTCHA_TEXT = () => `获取验证码`;

export const LOGIN_PAGE_INVALID_CREDS_ERROR = () =>
  `密码校验失败，请重试，或者点击下面的按钮重置密码`;
export const LOGIN_PAGE_INVALID_CREDS_FORGOT_PASSWORD_LINK = () => `重置密码`;
export const NEW_TO_APPSMITH = () => `是新朋友吗？`;

export const LOGIN_PAGE_LOGIN_BUTTON_TEXT = () => `登录`;
export const LOGIN_PAGE_FORGOT_PASSWORD_TEXT = () => `忘记密码`;
export const LOGIN_PAGE_REMEMBER_ME_LABEL = () => `记住我`;
export const LOGIN_PAGE_SIGN_UP_LINK_TEXT = () => `注册`;
export const SIGNUP_PAGE_TITLE = () => `免费注册账号`;
export const SIGNUP_PAGE_SUBTITLE = () => `使用你的团队邮箱`;
export const SIGNUP_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const SIGNUP_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `邮箱`;
export const SIGNUP_PAGE_NAME_INPUT_PLACEHOLDER = () => `昵称`;
export const SIGNUP_PAGE_NAME_INPUT_LABEL = () => `昵称`;
export const SIGNUP_PAGE_PASSWORD_INPUT_LABEL = () => `密码`;
export const SIGNUP_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `密码`;
export const SIGNUP_PAGE_LOGIN_LINK_TEXT = () => `登录`;
export const SIGNUP_PAGE_NAME_INPUT_SUBTEXT = () => `我们应该怎么称呼你？`;
export const SIGNUP_PAGE_SUBMIT_BUTTON_TEXT = () => `注册`;
export const ALREADY_HAVE_AN_ACCOUNT = () => `已经有账号了吗？`;

export const SIGNUP_PAGE_SUCCESS = () => `恭喜注册成功！`;
export const SIGNUP_PAGE_SUCCESS_LOGIN_BUTTON_TEXT = () => `登录`;

export const RESET_PASSWORD_PAGE_PASSWORD_INPUT_LABEL = () => `新密码`;
export const RESET_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `新密码`;
export const RESET_PASSWORD_LOGIN_LINK_TEXT = () => `返回登录`;
export const RESET_PASSWORD_PAGE_TITLE = () => `重置密码`;
export const RESET_PASSWORD_SUBMIT_BUTTON_TEXT = () => `重置`;
export const RESET_PASSWORD_PAGE_SUBTITLE = () => `重置你的账号密码 `;

export const RESET_PASSWORD_RESET_SUCCESS = () => `重置成功`; //`Your password has been reset. Please login` (see next entry));
export const RESET_PASSWORD_RESET_SUCCESS_LOGIN_LINK = () => `登录`;

export const RESET_PASSWORD_EXPIRED_TOKEN = () =>
  `密码重置链接已经失效，请重新生成新的链接`;
export const RESET_PASSWORD_INVALID_TOKEN = () =>
  `密码重置链接已经失效，请重新生成新的链接`;
export const RESET_PASSWORD_FORGOT_PASSWORD_LINK = () => `忘记密码`;

export const FORGOT_PASSWORD_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const FORGOT_PASSWORD_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `邮箱`;
export const FORGOT_PASSWORD_PAGE_TITLE = () => `重置密码`;
export const FORGOT_PASSWORD_PAGE_SUBTITLE = () =>
  `我们会将密码重置链接发送到下面的邮箱`;
export const FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT = () => `重置`;
export const FORGOT_PASSWORD_SUCCESS_TEXT = (email: string) =>
  `密码重置链接已经发送到你的邮箱 ${email} ，请查收确认`;

export const VERIFICATION_PENDING_TITLE = () => `请检查你的收件箱`;
export const VERIFICATION_PENDING_BODY = () =>
  `请点击我们给你发送的验证链接来完成注册`;

export const VERIFICATION_PENDING_NOT_YOU = () => `（不是你？）`;

export const VERIFICATION_PENDING_NO_EMAIL = () =>
  `没有收到邮件？请检查是否被归类到垃圾邮件`;

export const VERIFICATION_PENDING_RESEND_LINK = () => `重新发送`;

export const VERIFY_ERROR_ALREADY_VERIFIED_TITLE = () => `邮箱已验证`;

export const VERIFY_ERROR_EXPIRED_TITLE = () => "链接失效了";

export const VERIFY_ERROR_MISMATCH_TITLE = () => "链接被损坏，请重新请求链接";

export const PRIVACY_POLICY_LINK = () => `隐私条款`;
export const TERMS_AND_CONDITIONS_LINK = () => `条款协议`;

export const ERROR_500 = () => `抱歉，服务端出错了，我们正在拼命修复`;
export const ERROR_0 = () => `无法连接到服务端，请检查你的网络连接`;
export const ERROR_401 = () => `鉴权失败！请重新登录`;
export const ERROR_413 = (maxFileSize: number) =>
  `文件太大了，文件大小请不要超过 ${maxFileSize}MB`;
export const GENERIC_API_EXECUTION_ERROR = () => `API 执行出错`;
export const APPSMITH_HTTP_ERROR_413 = () => `413 CONTENT_TOO_LARGE`;
export const ERROR_403 = (entity: string, userEmail: string) =>
  `抱歉，你的账号 (${userEmail}) 没有权限更新 ${entity}，请联系管理员解决`;
export const PAGE_NOT_FOUND_ERROR = () => `页面不存在`;
export const INVALID_URL_ERROR = () => `无效地址`;
export const MAKE_APPLICATION_PUBLIC = () => "公开应用";
export const MAKE_APPLICATION_PUBLIC_TOOLTIP = () =>
  "让任何人无需登录即可访问你的应用";
export const INVITE_TAB = () => "邀请";
export const INVITE_USERS_VALIDATION_EMAIL_LIST = () => `包含无效邮箱地址`;
export const INVITE_USERS_VALIDATION_ROLE_EMPTY = () => `请选择角色`;
export const APPLICATION_INVITE = (name: string) => `邀请用户到 ${name}`;
export const INVITE_USERS_EMAIL_LIST_PLACEHOLDER = () => `邮箱之间用逗号分隔`;
export const INVITE_USERS_ROLE_SELECT_PLACEHOLDER = () => `请选择角色`;
export const INVITE_USERS_ROLE_SELECT_LABEL = () => `角色`;
export const INVITE_USERS_EMAIL_LIST_LABEL = () => `邮箱`;
export const INVITE_USERS_ADD_EMAIL_LIST_FIELD = () => `添加`;
export const INVITE_USERS_MESSAGE = () => `邀请小伙伴`;
export const INVITE_USERS_PLACEHOLDER = () => `请输入邮箱地址`;
export const INVITE_USERS_SUBMIT_BUTTON_TEXT = () => `邀请小伙伴`;
export const INVITE_USERS_SUBMIT_SUCCESS = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cloudHosting?: boolean,
) => `邀请成功`;
export const INVITE_USER_SUBMIT_SUCCESS = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cloudHosting?: boolean,
) => `邀请成功`;
export const INVITE_USERS_VALIDATION_EMAILS_EMPTY = () => `请输入小伙伴的邮箱`;

export const INVITE_USER_RAMP_TEXT = () =>
  "用户可以访问应用组中的所有应用。如果想要使用应用级别的访问控制，请看看我们的";
export const CUSTOM_ROLES_RAMP_TEXT = () => "如果想要自定义角色，请看看我们的";
export const CUSTOM_ROLE_TEXT = () => "自定义角色";
export const CUSTOM_ROLE_DISABLED_OPTION_TEXT = () =>
  "支持控制访问特定的应用、特定的页面、特定的查询";
export const USERS_HAVE_ACCESS_TO_ALL_APPS = () =>
  "用户可以访问应用组中的所有应用";
export const USERS_HAVE_ACCESS_TO_ONLY_THIS_APP = () => "用户只能访问这个应用";
export const NO_USERS_INVITED = () => "还没有邀请任何用户";
export const BUSINESS_EDITION_TEXT = () => "商业版";
export const PARTNER_PROGRAM_CALLOUT = (email: string) =>
  `${email} 不属于你的组织，如果你正在为其他人构建应用，请看看我们的伙伴计划`;
export const PARTNER_PROGRAM_CALLOUT_LINK = () => `了解 Appsmith 伙伴计划`;

export const HELP_MESSAGE = () => "帮助";
export const BUSINESS_TEXT = () => "商业版";

export const CREATE_PASSWORD_RESET_SUCCESS = () => `密码重置成功`;
export const CREATE_PASSWORD_RESET_SUCCESS_LOGIN_LINK = () => `登录`;

export const DELETING_APPLICATION = () => `正在删除应用...`;

export const FORGOT_PASSWORD_PAGE_LOGIN_LINK = () => `返回登录`;
export const ADD_API_TO_PAGE_SUCCESS_MESSAGE = (actionName: string) =>
  `${actionName} API 添加成功`;
export const INPUT_WIDGET_DEFAULT_VALIDATION_ERROR = () => `无效输入`;

export const AUTOFIT_ALL_COLUMNS = () => `自动填充所有列`;
export const AUTOFIT_THIS_COLUMN = () => `自动填充当前列`;
export const AUTOFIT_COLUMN = () => `自动填充列`;

export const DATE_WIDGET_DEFAULT_VALIDATION_ERROR = () => "日期超出限制";

export const TIMEZONE = () => `时区`;
export const ENABLE_TIME = () => `显示时间`;

export const EDIT_APP = () => `编辑应用`;
export const FORK_APP = () => `复制应用`;
export const SIGN_IN = () => `登录`;
export const SHARE_APP = () => `分享应用`;
export const ALL_APPS = () => `所有应用`;

export const USER_PROFILE_PICTURE_UPLOAD_FAILED = () => "图片上传失败";
export const UPDATE_USER_DETAILS_FAILED = () => "用户详情更新失败";
export const USER_DISPLAY_PICTURE_FILE_INVALID = () => "请上传有效的图片";
export const USER_DISPLAY_NAME_CHAR_CHECK_FAILED = () =>
  "请不要使用除 .'- 外的特殊字符";
export const USER_DISPLAY_NAME_PLACEHOLDER = () => "显示昵称";
export const USER_DISPLAY_PICTURE_PLACEHOLDER = () => "显示图片";
export const USER_EMAIL_PLACEHOLDER = () => "邮箱";
export const USER_RESET_PASSWORD = () => "重置密码";

export const DELETING_MULTIPLE_APPLICATION = () => `正在删除多个应用...`;
export const NO_PERMISSION_TO_SELECT_FOR_DELETE = () =>
  `没有删除这个应用的权限`;

export const DELETING_MULTIPLE_APPLICATION_MODAL_TITLE = () => `确认删除`;
export const DELETING_MULTIPLE_APPLICATION_MODAL_DESC = () =>
  `确定删除选中的应用吗？`;

export const EDITOR_HEADER = {
  saving: () => "正在保存",
  saveFailed: () => "保存失败",
  share: () => "分享",
  previewTooltip: {
    text: () => "预览",
    shortcut: () => "P",
  },
};

// Homepage
export const CREATE_NEW_APPLICATION = () => `新建应用`;
export const SEARCH_APPS = () => `搜索应用`;
export const GETTING_STARTED = () => `马上开始`;
export const WORKSPACES_HEADING = () => `应用组`;
export const WELCOME_TOUR = () => `新手引导`;
export const NO_APPS_FOUND = () => `没有发现相关应用`;
export const APPLICATION_CARD_LIST_ZERO_STATE = () => `应用组空空如也`;

// Lightning menu
export const LIGHTNING_MENU_DATA_API = () => `使用 API 数据`;
export const LIGHTNING_MENU_DATA_QUERY = () => `使用查询数据`;
export const LIGHTNING_MENU_DATA_TOOLTIP = () => `快速绑定数据`;
export const LIGHTNING_MENU_DATA_WIDGET = () => `使用组件数据`;
export const LIGHTNING_MENU_QUERY_CREATE_NEW = () => `新建查询`;
export const LIGHTNING_MENU_API_CREATE_NEW = () => `新建 API`;

export const LIGHTNING_MENU_OPTION_TEXT = () => `纯文本`;
export const LIGHTNING_MENU_OPTION_JS = () => `写 JS`;
export const LIGHTNING_MENU_OPTION_HTML = () => `写 HTML`;
export const CHECK_REQUEST_BODY = () => `请检查你的请求配置参数`;
export const DONT_SHOW_THIS_AGAIN = () => `不再提示`;

export const TABLE_FILTER_COLUMN_TYPE_CALLOUT = () => `修改列类型显示过滤操作`;

export const SAVE_HOTKEY_TOASTER_MESSAGE = () =>
  "不用担心保存的事情，请放心交给我们！";

export const WIDGET_SIDEBAR_TITLE = () => `组件`;
export const WIDGET_SIDEBAR_CAPTION = () => `把组件拖动到画布`;
export const GOOGLE_RECAPTCHA_KEY_ERROR = () =>
  `Google reCAPTCHA token 生成失败！请检查 reCAPTCHA 的键值`;
export const GOOGLE_RECAPTCHA_DOMAIN_ERROR = () =>
  `Google reCAPTCHA token 生成失败！请检查允许的域名`;

export const SERVER_API_TIMEOUT_ERROR = () => `服务器超时，请稍后重试`;
export const DEFAULT_ERROR_MESSAGE = () => `未知错误`;
export const REMOVE_FILE_TOOL_TIP = () => "删除上传";
export const ERROR_FILE_TOO_LARGE = (fileSize: string) =>
  `文件大小应该不超过 ${fileSize}!`;
export const ERROR_DATEPICKER_MIN_DATE = () => `超出最小日期限制`;
export const ERROR_DATEPICKER_MAX_DATE = () => `超出最大日期限制`;
export const ERROR_WIDGET_DOWNLOAD = (err: string) => `下载失败！ ${err}`;
export const ERROR_PLUGIN_ACTION_EXECUTE = (actionName: string) =>
  `${actionName} 动作运行失败`;
export const ACTION_EXECUTION_CANCELLED = (actionName: string) =>
  `${actionName} 已取消`;
export const ERROR_FAIL_ON_PAGE_LOAD_ACTIONS = () =>
  `页面加载后关联的动作运行失败`;
export const ERROR_ACTION_EXECUTE_FAIL = (actionName: string) =>
  `${actionName} 动作执行时出错了`;
export const ACTION_MOVE_SUCCESS = (actionName: string, pageName: string) =>
  `成功将动作 ${actionName} 移动到页面 ${pageName}`;
export const ERROR_ACTION_MOVE_FAIL = (actionName: string) =>
  `动作 ${actionName} 移动失败`;
export const ACTION_COPY_SUCCESS = (actionName: string, pageName: string) =>
  `成功将动作 ${actionName} 复制到页面 ${pageName}`;
export const ERROR_ACTION_COPY_FAIL = (actionName: string) =>
  `动作 ${actionName} 复制失败`;
export const ERROR_ACTION_RENAME_FAIL = (actionName: string) =>
  `动作名称更新失败 ${actionName}`;

// Action Names Messages
export const ACTION_NAME_PLACEHOLDER = (type: string) =>
  `${type} 名称（驼峰式）`;
export const ACTION_INVALID_NAME_ERROR = () => "请输入有效名称";
export const ACTION_NAME_CONFLICT_ERROR = (name: string) =>
  `${name} 已经被占用，或者是系统关键字`;
export const ENTITY_EXPLORER_ACTION_NAME_CONFLICT_ERROR = (name: string) =>
  `${name} 已经被占用`;

export const ACTION_ID_NOT_FOUND_IN_URL = "url 中没有正确的 API id 或 查询id";
export const JSOBJECT_ID_NOT_FOUND_IN_URL = "url 中没有正确的 脚本 id";

export const DATASOURCE_CREATE = (dsName: string) => `${dsName} 数据源创建成功`;
export const DATASOURCE_DELETE = (dsName: string) => `${dsName} 数据源删除成功`;
export const DATASOURCE_UPDATE = (dsName: string) => `${dsName} 数据源更新成功`;
export const DATASOURCE_VALID = (dsName: string) => `${dsName} 数据源连接成功`;
export const EDIT_DATASOURCE = () => "编辑数据源";
export const SAVE_DATASOURCE = () => "保存数据源";
export const SAVE_DATASOURCE_MESSAGE = () => "将 API 保存为数据源来启动鉴权";
export const EDIT_DATASOURCE_MESSAGE = () => "编辑数据源鉴权配置";
export const OAUTH_ERROR = () => "OAuth 错误";
export const OAUTH_2_0 = () => "OAuth 2.0";
export const ENABLE = () => "启用";
export const UPGRADE = () => "升级";
export const EDIT = () => "编辑";
export const CONFIGURE = () => "配置";
export const UNEXPECTED_ERROR = () => "出现了意外的错误";
export const EXPECTED_ERROR = () => "出错了";
export const NO_DATASOURCE_FOR_QUERY = () => `你还没有用来创建查询的数据源`;
export const ACTION_EDITOR_REFRESH = () => "刷新";
export const INVALID_FORM_CONFIGURATION = () => "无效表单配置";
export const ACTION_RUN_BUTTON_MESSAGE_FIRST_HALF = () => "🙌 点击";
export const ACTION_RUN_BUTTON_MESSAGE_SECOND_HALF = () => "在添加你的查询之后";
export const CREATE_NEW_DATASOURCE = () => "新建数据源";

export const ERROR_EVAL_ERROR_GENERIC = () => `应用解析出错了`;

export const ERROR_EVAL_TRIGGER = (message: string) =>
  `解析触发器出错: ${message}`;

export const WIDGET_COPY = (widgetName: string) => `${widgetName} 复制成功`;
export const ERROR_WIDGET_COPY_NO_WIDGET_SELECTED = () => `请选择要复制的组件`;
export const ERROR_WIDGET_COPY_NOT_ALLOWED = () => `不能复制该组件`;
export const WIDGET_CUT = (widgetName: string) => `${widgetName} 剪切成功`;
export const ERROR_WIDGET_CUT_NO_WIDGET_SELECTED = () => `请选择要剪切的组件`;
export const ERROR_WIDGET_CUT_NOT_ALLOWED = () => `不能剪切该组件`;
export const SELECT_ALL_WIDGETS_MSG = () => `已选中页面中所有的组件`;
export const ERROR_ADD_WIDGET_FROM_QUERY = () => `组件添加失败`;

export const REST_API_AUTHORIZATION_SUCCESSFUL = () => "鉴权成功";
export const REST_API_AUTHORIZATION_FAILED = () => "鉴权失败！请查看详情";
// Todo: improve this for appsmith_error error message
export const REST_API_AUTHORIZATION_APPSMITH_ERROR = () => "出错了";

export const OAUTH_AUTHORIZATION_SUCCESSFUL = "鉴权成功";
export const OAUTH_AUTHORIZATION_FAILED = "鉴权失败！请查看详情";
// Todo: improve this for appsmith_error error message
export const OAUTH_AUTHORIZATION_APPSMITH_ERROR = "出错了";
export const OAUTH_APPSMITH_TOKEN_NOT_FOUND = "没有发现 token";

export const GSHEET_AUTHORIZATION_ERROR = "数据源未授权，请授权后继续操作";
export const GSHEET_FILES_NOT_SELECTED = "无权限，请授权后再使用这个数据源";
export const FILES_NOT_SELECTED_EVENT = () => "未选择文件";

export const LOCAL_STORAGE_QUOTA_EXCEEDED_MESSAGE = () =>
  "本地存储失败！已超出本地最大存储限制";
export const LOCAL_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE = () =>
  "本地存储失败！磁盘不足";
export const LOCAL_STORAGE_NOT_SUPPORTED_APP_MIGHT_NOT_WORK_AS_EXPECTED = () =>
  "你的设备不支持本地存储！";

export const OMNIBAR_PLACEHOLDER = () => `搜索或者新建组件、查询`;
export const OMNIBAR_PLACEHOLDER_NAV = () => "搜索组件和查询";
export const CREATE_NEW_OMNIBAR_PLACEHOLDER = () =>
  "新建查询、API或者静态JS对象";
export const HELPBAR_PLACEHOLDER = () => "搜索";
export const NO_SEARCH_DATA_TEXT = () => "没有找到相关内容";

export const WIDGET_BIND_HELP = () => "不知道怎么从组件获取信息吗？";

export const BACK_TO_HOMEPAGE = () => "回到主页";

// error pages
export const PAGE_NOT_FOUND_TITLE = () => "404";
export const PAGE_NOT_FOUND = () => "未找到页面";
export const PAGE_SERVER_TIMEOUT_TITLE = () => "Appsmith 服务长时间无响应";
export const PAGE_SERVER_TIMEOUT_DESCRIPTION = () => `请稍后重试`;
export const PAGE_CLIENT_ERROR_TITLE = () => "糟糕，魔法失灵了！";
export const PAGE_CLIENT_ERROR_DESCRIPTION = () =>
  "请联系 Appsmith 团队寻求帮助";

export const PAGE_SERVER_UNAVAILABLE_ERROR_CODE = () => "503";

// cloudHosting used in EE
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PAGE_SERVER_UNAVAILABLE_TITLE = (cloudHosting: boolean) =>
  "Appsmith 服务异常";

export const PAGE_SERVER_UNAVAILABLE_DESCRIPTION = () => "请稍后重试";
export const PAGE_SERVER_TIMEOUT_ERROR_CODE = () => "504";

export const PAGE_SERVER_UNAVAILABLE_ERROR_MESSAGES = (
  cloudHosting: boolean,
): PageErrorMessageProps[] => {
  if (cloudHosting) {
    return [
      {
        text: "如果问题依然存在，请联系我们的客服人员",
        links: [
          {
            from: 40,
            to: 56,
            href: "mailto: support@appsmith.com?subject=Appsmith 503 Server Error",
          },
        ],
        addNewLine: true,
      },
    ];
  } else {
    return [
      {
        text: "如果问题依然存在，请联系你的系统管理员",
        addNewLine: true,
      },
      {
        text: "如何调试、查看日志",
        links: [
          {
            from: 66,
            to: 70,
            href: "https://docs.appsmith.com/learning-and-resources/how-to-guides/how-to-get-container-logs",
          },
        ],
        addNewLine: true,
      },
      {
        text: "快速查看后台日志",
        links: [
          {
            from: 46,
            to: 50,
            href: "/supervisor/logtail/backend",
          },
        ],
      },
    ];
  }
};

// comments
export const POST = () => "提交";
export const CANCEL = () => "取消";
export const REMOVE = () => "删除";

// Showcase Carousel
export const NEXT = () => "下一步";
export const BACK = () => "上一步";
export const SKIP = () => "跳过";

// Debugger
export const CLICK_ON = () => "🙌 点击 ";
export const PRESS = () => "🎉 按 ";
export const OPEN_THE_DEBUGGER = () => " 显示/隐藏调试器";
export const DEBUGGER_QUERY_RESPONSE_SECOND_HALF = () =>
  " 在调试器中显示更多信息";
export const LOGS_FILTER_OPTION_ALL = () => "所有日志";
export const LOGS_FILTER_OPTION_ERROR = () => "错误日志";
export const LOGS_FILTER_OPTION_CONSOLE = () => "控制台日志";
export const LOGS_FILTER_OPTION_SYSTEM = () => "系统日志";
export const NO_LOGS = () => "暂无日志";
export const NO_ERRORS = () => "🌈 一切顺利！";
export const DEBUGGER_ERRORS = () => "错误";
export const DEBUGGER_LOGS = () => "日志";
export const INSPECT_ENTITY = () => "检查实体";
export const INSPECT_ENTITY_BLANK_STATE = () => "请选择一个实体";
export const VALUE_IS_INVALID = (propertyPath: string) =>
  `${propertyPath} 取值无效`;
export const ACTION_CONFIGURATION_UPDATED = () => "配置已更新";
export const WIDGET_PROPERTIES_UPDATED = () => "组件属性已更新";
export const EMPTY_RESPONSE_FIRST_HALF = () => "🙌 点击";
export const EMPTY_RESPONSE_LAST_HALF = () => "获取响应";
export const EMPTY_JS_RESPONSE_LAST_HALF = () => "查看选中函数的返回值";
export const INVALID_EMAIL = () => "请输入有效邮箱";
export const DEBUGGER_INTERCOM_TEXT = (text: string) =>
  `你好， \n我遇到了下面的问题，请问能帮我看看吗？ \n\n${text}`;
export const DEBUGGER_TRIGGER_ERROR = (propertyName: string) =>
  `解析触发器 ${propertyName} 时出错了`;

export const TROUBLESHOOT_ISSUE = () => "答疑解惑";
export const DEBUGGER_OPEN_DOCUMENTATION = () => "打开文档";
export const DEBUGGER_SEARCH_SNIPPET = () => "查看代码片段";
export const DEBUGGER_APPSMITH_SUPPORT = () => "获取官方支持";

//action creator menu
export const NO_ACTION = () => `无动作`;
export const EXECUTE_A_QUERY = () => `执行查询`;
export const NAVIGATE_TO = () => `跳转到`;
export const SHOW_ALERT = () => `消息提示`;
export const SHOW_MODAL = () => `打开弹窗`;
export const CLOSE_MODAL = () => `关闭弹窗`;
export const CLOSE = () => `关闭`;
export const STORE_VALUE = () => `保存数据`;
export const REMOVE_VALUE = () => `删除数据`;
export const CLEAR_STORE = () => `清空数据`;
export const DOWNLOAD = () => `下载`;
export const COPY_TO_CLIPBOARD = () => `复制`;
export const RESET_WIDGET = () => `重置组件`;
export const EXECUTE_JS_FUNCTION = () => `执行 JS 函数`;
export const SET_INTERVAL = () => `设置定时器`;
export const CLEAR_INTERVAL = () => `清除定时器`;
export const GET_GEO_LOCATION = () => `获取定位`;
export const WATCH_GEO_LOCATION = () => `实时定位`;
export const STOP_WATCH_GEO_LOCATION = () => `停止实时定位`;
export const POST_MESSAGE = () => `发消息`;

//js actions
export const JS_ACTION_COPY_SUCCESS = (actionName: string, pageName: string) =>
  `成功将动作 ${actionName} 复制到页面 ${pageName}`;
export const ERROR_JS_ACTION_COPY_FAIL = (actionName: string) =>
  `${actionName} 复制失败`;
export const JS_ACTION_DELETE_SUCCESS = (actionName: string) =>
  `${actionName} 删除成功`;
export const JS_ACTION_MOVE_SUCCESS = (actionName: string, pageName: string) =>
  `成功将动作 ${actionName} 移动到页面 ${pageName}`;
export const ERROR_JS_ACTION_MOVE_FAIL = (actionName: string) =>
  `${actionName} 移动失败`;
export const ERROR_JS_COLLECTION_RENAME_FAIL = (actionName: string) =>
  `不能将 JS 名称改为 ${actionName}`;
export const PARSE_JS_FUNCTION_ERROR = (message: string) =>
  `语法错误：${message}`;

export const EXECUTING_FUNCTION = () => `执行函数`;
export const UPDATING_JS_COLLECTION = () => `正在更新...`;
export const EMPTY_JS_OBJECT = () => `空空如也。写点 js 代码试试`;
export const EXPORT_DEFAULT_BEGINNING = () => `对象用 export default 开头`;
export const ACTION_EXECUTION_FAILED = (actionName: string) =>
  `"${actionName}" 执行失败`;
export const JS_EXECUTION_SUCCESS = () => "JS 函数执行成功";
export const JS_EXECUTION_FAILURE = () => "JS 函数执行失败";
export const JS_EXECUTION_FAILURE_TOASTER = () => "函数执行时出错了";
export const JS_SETTINGS_ONPAGELOAD = () => "页面加载后执行函数 (测试版)";
export const JS_EXECUTION_SUCCESS_TOASTER = (actionName: string) =>
  `${actionName} 执行成功`;
export const JS_SETTINGS_ONPAGELOAD_SUBTEXT = () =>
  "会在每次页面加载后刷新数据";
export const JS_SETTINGS_CONFIRM_EXECUTION = () =>
  "执行函数之前需要用户确认吗？";
export const JS_SETTINGS_CONFIRM_EXECUTION_SUBTEXT = () =>
  "每次刷新数据之前请求用户确认";
export const JS_SETTINGS_EXECUTE_TIMEOUT = () => "函数超时（毫秒）";
export const FUNCTION_SETTINGS_HEADING = () => "函数设置";
export const NO_JS_FUNCTIONS = () => "这个 JS 对象中没有函数";
export const NO_JS_FUNCTION_TO_RUN = (JSObjectName: string) =>
  `${JSObjectName} 没有函数`;
export const NO_JS_FUNCTION_RETURN_VALUE = (JSFunctionName: string) =>
  `${JSFunctionName} 没有返回任何数据，你给函数添加了返回吗？`;

// Import/Export Application features
export const ERROR_IMPORTING_APPLICATION_TO_WORKSPACE = () =>
  "导入应用失败，没有找到应用组";
export const IMPORT_APPLICATION_MODAL_TITLE = () => "导入应用";
export const IMPORT_APPLICATION_MODAL_LABEL = () => "你想从哪里导入你的应用？";
export const IMPORT_APP_FROM_FILE_TITLE = () => "从文件导入";
export const UPLOADING_JSON = () => "上传 JSON 文件";
export const UPLOADING_APPLICATION = () => "正在上传应用";
export const IMPORT_APP_FROM_GIT_TITLE = () => "从 Git 仓库导入（测试版）";
export const IMPORT_APP_FROM_FILE_MESSAGE = () =>
  "将文件拖拽到这里，或点击上传";
export const IMPORT_APP_FROM_GIT_MESSAGE = () => "填写 Git 仓库地址导入应用";
export const IMPORT_FROM_GIT_REPOSITORY = () => "从 Git 仓库导入";
export const RECONNECT_MISSING_DATASOURCE_CREDENTIALS = () =>
  "重新配置数据源信息";
export const RECONNECT_MISSING_DATASOURCE_CREDENTIALS_DESCRIPTION = () =>
  "请仔细填写，否则应用可能会运行异常";
export const RECONNECT_DATASOURCE_SUCCESS_MESSAGE1 = () => "数据源导入成功！";
export const RECONNECT_DATASOURCE_SUCCESS_MESSAGE2 = () => "请填写缺失的数据源";
export const ADD_MISSING_DATASOURCES = () => "添加缺失的数据源";
export const SKIP_TO_APPLICATION_TOOLTIP_HEADER = () => "这个操作是不可逆的";
export const SKIP_TO_APPLICATION_TOOLTIP_DESCRIPTION = () =>
  `跳过步骤，稍后配置数据源`;
export const SKIP_TO_APPLICATION = () => "跳转到应用";
export const SKIP_CONFIGURATION = () => "跳过设置";
export const SELECT_A_METHOD_TO_ADD_CREDENTIALS = () => "选择一种鉴权方式";
export const DELETE_CONFIRMATION_MODAL_TITLE = () => `确认`;
export const DELETE_CONFIRMATION_MODAL_SUBTITLE = (
  name?: string | null,
  entityType?: string,
) =>
  `确定从这个${
    entityType === "Application" ? "应用" : "应用组"
  }中删除 ${name} 吗`;
export const PARSING_ERROR = () =>
  "语法错误：无法解析代码，请检查错误日志来调试问题";
export const PARSING_WARNING = () =>
  "格式错误：请在使用这些函数前检查它们的格式错误";
export const JS_FUNCTION_CREATE_SUCCESS = () => "JS 函数创建成功";
export const JS_FUNCTION_UPDATE_SUCCESS = () => "JS 函数更新成功";
export const JS_FUNCTION_DELETE_SUCCESS = () => "JS 函数删除成功";
export const JS_OBJECT_BODY_INVALID = () => "无法解析脚本";
export const JS_ACTION_EXECUTION_ERROR = (jsFunctionName: string) =>
  `执行函数 ${jsFunctionName} 时出错了，具体请查看错误日志`;
//Editor Page
export const EDITOR_HEADER_SAVE_INDICATOR = () => "已保存";

//undo redo
export const WIDGET_REMOVED = (widgetName: string) => `${widgetName} 已删除`;
export const WIDGET_ADDED = (widgetName: string) => `${widgetName} 恢复成功`;
export const BULK_WIDGET_REMOVED = (widgetName: string) =>
  `${widgetName} 已删除`;
export const BULK_WIDGET_ADDED = (widgetName: string) =>
  `${widgetName} 恢复成功`;

export const ACTION_CONFIGURATION_CHANGED = (name: string) =>
  `${name} 的配置已更新`;

// Generate page from DB Messages

export const UNSUPPORTED_PLUGIN_DIALOG_TITLE = () =>
  `无法使用这个数据源自动生成页面`;

export const UNSUPPORTED_PLUGIN_DIALOG_SUBTITLE = () =>
  `你可以继续使用拖拽方式构建你的应用`;
export const UNSUPPORTED_PLUGIN_DIALOG_MAIN_HEADING = () => `自动生成出错了`;

export const BUILD_FROM_SCRATCH_ACTION_SUBTITLE = () => "从空白页面开始";

export const BUILD_FROM_SCRATCH_ACTION_TITLE = () => "手动拖拽构建";

export const GENERATE_PAGE_ACTION_TITLE = () => "使用数据表生成";

export const GENERATE_PAGE_ACTION_SUBTITLE = () => "从增删查改页面开始";

export const GENERATE_PAGE_FORM_TITLE = () => "用数据生成";

export const GEN_CRUD_SUCCESS_MESSAGE = () => "恭喜！你的应用已经可以使用了";
export const GEN_CRUD_INFO_DIALOG_TITLE = () => "它是怎么玩的？";
export const GEN_CRUD_INFO_DIALOG_SUBTITLE = () => "增删查改页面已经自动生成";
export const GEN_CRUD_COLUMN_HEADER_TITLE = () => "数据列头已加载";
export const GEN_CRUD_NO_COLUMNS = () => "没有发现数据列";
export const GEN_CRUD_DATASOURCE_DROPDOWN_LABEL = () => "选择数据源";
export const GEN_CRUD_TABLE_HEADER_LABEL = () => "表头索引";
export const GEN_CRUD_TABLE_HEADER_TOOLTIP_DESC = () => "数据表中的索引";
// Actions Right pane
export const SEE_CONNECTED_ENTITIES = () => "查看所有已连接实体";
export const INCOMING_ENTITIES = () => "输入实体";
export const NO_INCOMING_ENTITIES = () => "无输入实体";
export const OUTGOING_ENTITIES = () => "输出实体";
export const NO_OUTGOING_ENTITIES = () => "无输出实体";
export const NO_CONNECTIONS = () => "暂无连接";
export const BACK_TO_CANVAS = () => "返回画布";
export const SUGGESTED_WIDGET_DESCRIPTION = () => "这会在画布中新建一个组件";
export const ADD_NEW_WIDGET = () => "添加新组件";
export const SUGGESTED_WIDGETS = () => "推荐组件";
export const SUGGESTED_WIDGET_TOOLTIP = () => "添加到画布";
export const WELCOME_TOUR_STICKY_BUTTON_TEXT = () => "下一步";
export const BINDING_SECTION_LABEL = () => "数据绑定";
export const ADD_NEW_WIDGET_SUB_HEADING = () => "选择渲染组件";
export const CONNECT_EXISTING_WIDGET_LABEL = () => "选择一个组件";
export const CONNECT_EXISTING_WIDGET_SUB_HEADING = () => "替换现有组件的数据";
export const NO_EXISTING_WIDGETS = () => "在新组件渲染数据";
export const BINDING_WALKTHROUGH_TITLE = () => "渲染数据";
export const BINDING_WALKTHROUGH_DESC = () =>
  "你可以替换现有组件的数据，或者选择一个新的组件";
export const BINDINGS_DISABLED_TOOLTIP = () =>
  "你可以在查询成功返回数据后把数据渲染出来";

// Data Sources pane
export const EMPTY_ACTIVE_DATA_SOURCES = () => "暂无有效数据源";

// Datasource structure

export const SCHEMA_NOT_AVAILABLE = () => "数据库结构不可用";
export const TABLE_NOT_FOUND = () => "没有找到表";
export const DATASOURCE_STRUCTURE_INPUT_PLACEHOLDER_TEXT = () =>
  "搜索表格或字段";
export const SCHEMA_LABEL = () => "数据库结构";
export const STRUCTURE_NOT_FETCHED = () => "无法获取数据库结构";
export const TEST_DATASOURCE_AND_FIX_ERRORS = () => "测试数据库来修复错误";
export const LOADING_SCHEMA = () => "正在加载数据库结构...";
export const SCHEMA_WALKTHROUGH_TITLE = () => "快速查询";
export const SCHEMA_WALKTHROUGH_DESC = () =>
  "从数据库表中选择一个模板来快速创建查询";
export const SUGGESTED_TAG = () => "建议";

// structure - View Mode

export const DATASOURCE_VIEW_DATA_TAB = () => "查看数据";
export const DATASOURCE_CONFIGURATIONS_TAB = () => "配置";
export const DATASOURCE_NO_RECORDS_TO_SHOW = () => "暂无数据";

// Git sync
export const CONNECTED_TO_GIT = () => "已连接到 Git";

export const GIT_DISCONNECT_POPUP_TITLE = () =>
  `这会取消当前应用与 Git 仓库的连接`;

export const GIT_DISCONNECT_POPUP_SUBTITLE = () =>
  `Git 相关功能不会在应用中展示`;
export const GIT_DISCONNECT_POPUP_MAIN_HEADING = () => `确认`;

export const CONFIGURE_GIT = () => "配置 git";
export const IMPORT_APP = () => "从 git 导入应用";
export const SETTINGS_GIT = () => "设置";

export const GIT_CONNECTION = () => "Git 连接";
export const GIT_IMPORT = () => "Git 导入";
export const MERGE = () => "合并";
export const GIT_SETTINGS = () => "Git 配置";
export const CONNECT_TO_GIT = () => "连接到 Git 仓库";
export const CONNECT_TO_GIT_SUBTITLE = () => "查看分支、提交、部署应用";
export const REMOTE_URL = () => "远程地址";
export const REMOTE_URL_INFO = () =>
  `新建一个空的 Git 仓库，然后把地址粘贴到这里`;
export const IMPORT_URL_INFO = () => `代码库地址粘贴到这里：`;
export const REMOTE_URL_VIA = () => "代码库地址通过";

export const USER_PROFILE_SETTINGS_TITLE = () => "个人配置";
export const GIT_USER_SETTINGS_TITLE = () => "Git 作者";

export const USE_DEFAULT_CONFIGURATION = () => "使用默认配置";
export const AUTHOR_NAME = () => "作者名称";
export const AUTHOR_NAME_CANNOT_BE_EMPTY = () => "作者名称不能未空";
export const AUTHOR_EMAIL = () => "作者邮箱地址";
export const AUTHOR_EMAIL_CANNOT_BE_EMPTY = () => "作者邮箱不能为空";

export const NAME_YOUR_NEW_BRANCH = () => "新分支名称";
export const SWITCH_BRANCHES = () => "切换分支";

export const DOCUMENTATION = () => "帮助文档";
export const DOCUMENTATION_TOOLTIP = () => "在新窗口打开文档";
export const CONNECT = () => "连接";
export const LATEST_DP_TITLE = () => "最新部署预览";
export const LATEST_DP_SUBTITLE = () => "最近一次部署";
export const CHECK_DP = () => "检查";
export const DEPLOY_TO_CLOUD = () => "发布到云端";
export const DEPLOY_WITHOUT_GIT = () => "不使用版本控制，直接发布你的应用";
export const COMMIT_CHANGES = () => "提交更新";
export const COMMIT_TO = () => "提交到";
export const COMMIT_AND_PUSH = () => "提交 & 推送";
export const PULL_CHANGES = () => "拉取更新";
export const REGENERATE_SSH_KEY = (keyType: string, keySize: number) =>
  `重新生成 ${keyType} ${keySize} key`;
export const GENERATE_SSH_KEY = (keyType: string, keySize: number) =>
  `${keyType} ${keySize} key`;
export const SSH_KEY_PLATFORM = (name: string) => ` (${name})`;
export const SSH_KEY = () => "SSH Key";
export const COPY_SSH_KEY = () => "复制 SSH Key";
export const SSH_KEY_GENERATED = () => "SSH Key 已生成";
export const REGENERATE_KEY_CONFIRM_MESSAGE = () =>
  "这可能会导致应用功能异常，同时你也需要在 Git 仓库里更新 key";
export const DEPLOY_KEY_USAGE_GUIDE_MESSAGE = () =>
  "将 key 拷贝到你的仓库设置中，并给它赋予写权限";
export const COMMITTING_AND_PUSHING_CHANGES = () => "正在提交、推送修改...";
export const DISCARDING_AND_PULLING_CHANGES = () => "正在丢弃、拉取修改...";
export const DISCARD_SUCCESS = () => "修改丢弃成功";

export const IS_MERGING = () => "合并修改...";

export const MERGE_CHANGES = () => "合并修改";
export const SELECT_BRANCH_TO_MERGE = () => "选择要合并的分支";
export const CONNECT_GIT = () => "连接 Git";
export const CONNECT_GIT_BETA = () => "连接 Git (测试版)";
export const RETRY = () => "重试";
export const CREATE_NEW_BRANCH = () => "新建分支";
export const ERROR_WHILE_PULLING_CHANGES = () => "拉取时出错了";
export const SUBMIT = () => "提交";
export const GIT_USER_UPDATED_SUCCESSFULLY = () => "Git 用户更新成功";
export const REMOTE_URL_INPUT_PLACEHOLDER = () =>
  "git@example.com:user/repo.git";
export const GIT_COMMIT_MESSAGE_PLACEHOLDER = () => "你的提交信息";
export const INVALID_USER_DETAILS_MSG = () => "请输入有效用户信息";
export const PASTE_SSH_URL_INFO = () => "请输入代码仓库的 SSH 地址";
export const GENERATE_KEY = () => "生成 Key";
export const UPDATE_CONFIG = () => "更新配置";
export const CONNECT_BTN_LABEL = () => "连接";
export const IMPORT_BTN_LABEL = () => "导入";
export const FETCH_GIT_STATUS = () => "查看仓库状态...";
export const FETCH_MERGE_STATUS = () => "检查是否可以合并...";
export const NO_MERGE_CONFLICT = () => "这个分支和基准分支没有冲突";
export const MERGE_CONFLICT_ERROR = () => "发现合并冲突！";
export const FETCH_MERGE_STATUS_FAILURE = () => "拉取合并状态失败";
export const GIT_UPSTREAM_CHANGES = () =>
  "上游仓库有更新，我们将拉取更新并推送到你的仓库";
export const GIT_CONFLICTING_INFO = () => "请在你的仓库中手动解决冲突";
export const CANNOT_PULL_WITH_LOCAL_UNCOMMITTED_CHANGES = () =>
  "你还有未提交的更新，请在拉取前提交更新.";
export const CANNOT_MERGE_DUE_TO_UNCOMMITTED_CHANGES = () =>
  "你当前分支还有未提交的更新，请在合并之前提交更新";

export const DISCONNECT_SERVICE_SUBHEADER = () =>
  "修改这个配置会打断用户登录，请谨慎操作";
export const DISCONNECT_SERVICE_WARNING = () => "这个身份认证方式会被删除";
export const AUTHENTICATION_METHOD_ENABLED = (methodName: string) => `
  ${methodName} 身份认证已生效
`;

export const REVOKE_EXISTING_REPOSITORIES = () => "与现有仓库断开连接";
export const REVOKE_EXISTING_REPOSITORIES_INFO = () =>
  "你可以删除现有的仓库来给新的仓库腾出空间";
export const CONTACT_SUPPORT = () => "联系我们";
export const CONTACT_SALES_MESSAGE_ON_INTERCOM = (workspaceName: string) =>
  `你好！如果你想为你的团队 ${workspaceName} 扩充私有仓库数量，请告诉我们你想扩充多少个仓库以及扩充的原因，我们会很快回复你。`;
export const REPOSITORY_LIMIT_REACHED = () => "仓库数量达到限制";
export const REPOSITORY_LIMIT_REACHED_INFO = () =>
  "最多免费使用 3 个仓库，如需使用更多仓库请升级";
export const APPLICATION_IMPORT_SUCCESS = () => `你的应用已经准备好了！`;
export const APPLICATION_IMPORT_SUCCESS_DESCRIPTION = () =>
  "你所有的数据源都已经准备好了";
export const NONE_REVERSIBLE_MESSAGE = () => "这个操作是不可恢复的，请谨慎操作";
export const CONTACT_SUPPORT_TO_UPGRADE = () =>
  "联系我们进行升级，升级版可以使用无限的私有仓库";
export const REVOKE_CAUSE_APPLICATION_BREAK = () =>
  "断开连接可能会导致应用出错";
export const REVOKE_GIT = () => "取消访问";
export const DISCONNECT = () => "断开连接";
export const REVOKE = () => "取消";
export const REVOKE_ACCESS = () => "取消访问";
export const GIT_DISCONNECTION_SUBMENU = () => "Git 连接 > 断开连接";
export const DISCONNECT_FROM_GIT = (name: string) =>
  `断开 ${name} 和 Git 的连接`;
export const GIT_REVOKE_ACCESS = (name: string) => `取消访问 ${name}`;
export const GIT_TYPE_REPO_NAME_FOR_REVOKING_ACCESS = (name: string) =>
  `在输入框中输入 “${name}” 来取消访问`;
export const APPLICATION_NAME = () => "应用名称";
export const NOT_OPTIONS = () => "没有可选项！";
export const OPEN_REPO = () => "打开仓库SITORY";
export const CONNECTING_REPO = () => "连接到 git 仓库sitory";
export const IMPORTING_APP_FROM_GIT = () => "从 git 导入应用";
export const ERROR_CONNECTING = () => "连接时出错";
export const ERROR_COMMITTING = () => "提交时出错";
export const CONFIRM_SSH_KEY = () => "请确保你的 SSH Key 有写权限";
export const READ_DOCUMENTATION = () => "查看文档";
export const LEARN_MORE = () => "了解更多";

export const I_UNDERSTAND = () => "了解";
export const GIT_NO_UPDATED_TOOLTIP = () => "没有更新";

export const FIND_OR_CREATE_A_BRANCH = () => "查找或创建一个分支";
export const SYNC_BRANCHES = () => "同步分支";

export const CONFLICTS_FOUND = () => "发现冲突，请解决冲突然后重新拉取";
export const UNCOMMITTED_CHANGES = () => "你有未提交的修改";
export const NO_COMMITS_TO_PULL = () => "已经和远程仓库保持同步";
export const CONFLICTS_FOUND_WHILE_PULLING_CHANGES = () => "拉取更新时发现冲突";
export const NOT_LIVE_FOR_YOU_YET = () => "暂不可用";
export const COMING_SOON = () => "敬请期待！";
export const CONNECTING_TO_REPO_DISABLED = () => "连接 git 仓库功能被禁用";
export const DURING_ONBOARDING_TOUR = () => "在欢迎引导中";
export const MERGED_SUCCESSFULLY = () => "合并成功";
export const DISCARD_CHANGES_WARNING = () =>
  "这个动作会使用最新的远程版本替换你的本地修改";
export const DISCARD_CHANGES = () => "丢弃 & 拉取";

// GIT DEPLOY begin
export const DEPLOY = () => "发布";
export const DEPLOY_YOUR_APPLICATION = () => "发布你的应用";
export const CHANGES_APP_SETTINGS = () => "Application settings modified";
export const CHANGES_THEME = () => "Theme modified";
export const CHANGES_SINCE_LAST_DEPLOYMENT = () => "上次发布以来的修改";
export const CHANGES_ONLY_USER = () => "上次提交以来的用户修改";
export const CHANGES_MADE_SINCE_LAST_COMMIT = () => "上次提交以来的修改";
export const CHANGES_ONLY_MIGRATION = () => "上次提交以来 Appsmith 的更新";
export const CHANGES_USER_AND_MIGRATION = () =>
  "上次提交以来 Appsmith 的更新和用户修改";
export const CURRENT_PAGE_DISCARD_WARNING = (page: string) =>
  `当前页面 (${page}) 在丢弃列表中`;
export const DISCARD_MESSAGE = () =>
  `一些修改可能会在丢弃它们之后重新出现，这些修改支持系统的新特性。你可以放心的将它们提交到仓库。`;
// GIT DEPLOY end

// GIT CHANGE LIST begin
export const CHANGES_FROM_APPSMITH = () => "一些修改是 Appsmith 平台本身的升级";
export const TRY_TO_PULL = () => "在推送修改之前我们会尝试拉取最新的改动";
export const NOT_PUSHED_YET = () => "这些是未推送到远程仓库的提交";
// GIT CHANGE LIST end

// GIT DELETE BRANCH begin
export const DELETE = () => "删除";
export const LOCAL_BRANCHES = () => "本地分支";
export const REMOTE_BRANCHES = () => "远程分支";

export const DELETE_BRANCH_SUCCESS = (branchName: string) =>
  `成功删除分支：${branchName}`;

// warnings
export const DELETE_BRANCH_WARNING_CHECKED_OUT = (currentBranchName: string) =>
  `无法删除当前分支，在删除分支 ${currentBranchName} 之前，请切换到其他分支`;
export const DELETE_BRANCH_WARNING_DEFAULT = (defaultBranchName: string) =>
  `无法删除默认分支：${defaultBranchName}`;
// GIT DELETE BRANCH end

// GIT ERRORS begin
export const ERROR_GIT_AUTH_FAIL = () =>
  "请仓库确认已经添加了重新生成的 SSH key，并且赋予了写权限";
export const ERROR_GIT_INVALID_REMOTE = () => "远程仓库不存在或者无法访问";
// GIT ERRORS end

// Git Connect V2
export const CHOOSE_A_GIT_PROVIDER_STEP = () => "选择一个 git 服务";
export const GENERATE_SSH_KEY_STEP = () => "生成 SSH key";
export const ADD_DEPLOY_KEY_STEP = () => "添加部署 key";

export const CHOOSE_GIT_PROVIDER_QUESTION = () => "首先，请选择你的 git 服务";
export const IS_EMPTY_REPO_QUESTION = () =>
  "请问你已经有了能够连接到 git 的空仓库吗？";
export const HOW_TO_CREATE_EMPTY_REPO = () => "如何新建仓库？";
export const IMPORT_APP_IF_NOT_EMPTY = () =>
  "你可以把一个已连接到 git 的应用导入到你的应用组";
export const I_HAVE_EXISTING_REPO = () =>
  "我已经有了一个已连接到 git 的 appsmith 应用";
export const ERROR_REPO_NOT_EMPTY_TITLE = () => "你添加的仓库不是空的";
export const ERROR_REPO_NOT_EMPTY_MESSAGE = () =>
  "新建一个仓库并且把它的远程 SSH 地址放到这里，我们需要一个空的仓库来继续下面的操作";
export const READ_DOCS = () => "查看文档";
export const COPY_SSH_URL_MESSAGE = () =>
  "请到你的代码仓库中拷贝 SSH 地址，然后粘贴到下面的输入框";
export const REMOTE_URL_INPUT_LABEL = () => "远程 SSH 地址";
export const HOW_TO_COPY_REMOTE_URL = () => "如何拷贝、粘贴 SSH 远程地址";
export const ERROR_SSH_KEY_MISCONF_TITLE = () => "SSH key 配置错误";
export const ERROR_SSH_KEY_MISCONF_MESSAGE = () =>
  "貌似你的 SSH key 并没有添加到你的仓库，请仔细检查之前的步骤并且正确配置你的 SSH key";
export const ADD_DEPLOY_KEY_STEP_TITLE = () => "添加部署key，并且赋予写入权限";
export const HOW_TO_ADD_DEPLOY_KEY = () =>
  "如何在你的仓库粘贴 SSH Key 并且赋予写入权限？";
export const CONSENT_ADDED_DEPLOY_KEY = () =>
  "我已经添加了部署key并且赋予了写入权限";
export const PREVIOUS_STEP = () => "上一步";
export const GIT_CONNECT_SUCCESS_TITLE = () => "成功连接到你的 git 远程仓库";
export const GIT_CONNECT_SUCCESS_MESSAGE = () =>
  "现在你可以开始和团队小伙伴一起共同开发应用了";
export const START_USING_GIT = () => "开始使用 git";

export const GIT_AUTHOR = () => "Git 作者";
export const DISCONNECT_GIT = () => "断开 git 连接";
export const DISCONNECT_GIT_MESSAGE = () => "仓库一旦删除不可恢复，请三思";
export const NEED_EMPTY_REPO_MESSAGE = () =>
  "首先，你需要一个空的仓库来连接 Git，请在你的 Git 服务中创建它";
export const GIT_IMPORT_WAITING = () => "请稍等，正在导入应用中...";
export const GIT_CONNECT_WAITING = () => "请稍等，正在连接到 git...";
export const CONNECT_GIT_TEXT = () => "连接 git";

export const ERROR_SSH_RECONNECT_MESSAGE = () =>
  "缺少部署key，无法连接到仓库，你可以通过下面两步来解决：";
export const ERROR_SSH_RECONNECT_OPTION1 = () =>
  "拷贝下面的 SSH key 并添加到你的仓库";
export const ERROR_SSH_RECONNECT_OPTION2 = () =>
  "如果你想连接新的仓库，请先断开之前的连接";
export const COPIED_SSH_KEY = () => "SSH key 拷贝成功";
export const NO_COPIED_SSH_KEY = () => "SSH key 拷贝失败";
// Git Connect V2 end

export const NAV_DESCRIPTION = () => `导航到任意页面、组件或者文件`;
export const ACTION_OPERATION_DESCRIPTION = () => `新建查询、API 或者脚本`;
export const TABLE_WIDGET_VALIDATION_ASSIST_PROMPT = () =>
  `这样访问当前单元格：`;

export const TRIGGER_ACTION_VALIDATION_ERROR = (
  functionName: string,
  argumentName: string,
  expectedType: string,
  received: string,
) =>
  `${functionName} 期望 '${argumentName}' 参数是 ${expectedType} 类型的，但拿到的是 ${received} 类型`;

// Comment card tooltips
export const MORE_OPTIONS = () => "更多操作";
export const ADD_REACTION = () => "回复";
export const RESOLVE_THREAD = () => "处理对话";
export const RESOLVED_THREAD = () => "已处理的对话";
export const EMOJI = () => "Emoji表情";

// Sniping mode messages
export const SNIPING_SELECT_WIDGET_AGAIN = () =>
  "无法检测到组件，请重新选择组件";

export const SNIPING_NOT_SUPPORTED = () => "暂不支持绑定该类型组件！";

//First Time User Onboarding
//Checklist page
export enum ONBOARDING_CHECKLIST_ACTIONS {
  CONNECT_A_DATASOURCE = "连接数据源",
  CREATE_A_QUERY = "创建查询",
  ADD_WIDGETS = "添加组件",
  CONNECT_DATA_TO_WIDGET = "给组件绑定数据",
  DEPLOY_APPLICATIONS = "发布应用",
}

export const ONBOARDING_CHECKLIST_BANNER_HEADER = () =>
  "太棒了！看来你已经学会怎么用 Appsmith 啦";
export const ONBOARDING_CHECKLIST_BANNER_BODY = () =>
  "你可以去主页看看你创建的应用";
export const ONBOARDING_CHECKLIST_BANNER_BUTTON = () => "探索主页";
export const ONBOARDING_SKIPPED_FIRST_TIME_USER = () => "跳过新手指引";
export const ONBOARDING_CHECKLIST_HEADER = () => "👋 欢迎来到 Appsmith ！";
export const ONBOARDING_CHECKLIST_BODY = () =>
  "开始你的第一个应用吧，你可以自由探索，或者跟随指引了解 Appsmith 的基本用法";
export const ONBOARDING_CHECKLIST_COMPLETE_TEXT = () => "完成";

export const SIGNPOSTING_POPUP_SUBTITLE = () =>
  "通过下面的操作来构建你的第一个应用：";
export const SIGNPOSTING_SUCCESS_POPUP = {
  title: () => "🎉 太棒了！你已经体验了 Appsmith 的基本功能",
  subtitle: () =>
    "现在可以继续构建你的应用了，如果还有什么不清楚的地方请查看文档，或者使用我们的新手引导",
};

export const ONBOARDING_CHECKLIST_CONNECT_DATA_SOURCE = {
  bold: () => "连接你的数据源",
  normal: () => "开始构建应用",
};

export const ONBOARDING_CHECKLIST_CREATE_A_QUERY = {
  bold: () => "创建查询",
  normal: () => "导入你的数据到 Appsmith",
};

export const ONBOARDING_CHECKLIST_ADD_WIDGETS = {
  bold: () => "可视化构建你的应用",
  normal: () => "使用组件",
};

export const ONBOARDING_CHECKLIST_CONNECT_DATA_TO_WIDGET = {
  bold: () => "给组件绑定数据",
  normal: () => "使用 JavaScript 绑定",
};

export const ONBOARDING_CHECKLIST_DEPLOY_APPLICATIONS = {
  bold: () => "发布你的应用",
  normal: () => "你可以看到应用立即可用了",
};

export const SIGNPOSTING_LAST_STEP_TOOLTIP = () => "就快好了！";
export const SIGNPOSTING_TOOLTIP = {
  DEFAULT: {
    content: () =>
      "通过下面的 5 步学习如何构建应用并发布它，大概需要你 5 分钟时间",
  },
  CONNECT_A_DATASOURCE: {
    content: () => "添加数据源",
  },
  CREATE_QUERY: {
    content: () => "数据源已连接，让我们编写第一个查询吧",
  },
  ADD_WIDGET: {
    content: () => "这个查询看起来不错，接下来让我们构建 UI 界面",
  },
  CONNECT_DATA_TO_WIDGET: {
    content: () =>
      "很轻松吧，现在让我们把步骤2中编写的查询和组件连接起来，让组件能够显示出查询的数据",
  },
  DEPLOY_APPLICATION: {
    content: () => "发布你的应用，并且把应用分享给你的用户",
  },
  DOCUMENTATION: {
    content: () => "打开文档",
  },
};

export const ONBOARDING_CHECKLIST_FOOTER = () =>
  "不知道从何下手？请跟随我们的指引进行操作吧";

export const ONBOARDING_TELEMETRY_POPUP = () =>
  "我们收集使用数据来让 Appsmith 变得更好，你可以在管理员设置中关闭它";

//Introduction modal
export const HOW_APPSMITH_WORKS = () => "这是 Appsmith 的功能概述";
export const ONBOARDING_INTRO_CONNECT_YOUR_DATABASE = () =>
  "连接你的数据源或者 API";
export const DRAG_AND_DROP = () => "拖拽预置的组件构建 UI";
export const CUSTOMIZE_WIDGET_STYLING = () =>
  "自定义每个组件的样式风格，然后把数据绑定到组件上，使用 JS 轻松完成业务逻辑";
export const ONBOARDING_INTRO_PUBLISH = () => "自由控制权限的发布和分享";
export const CHOOSE_ACCESS_CONTROL_ROLES = () =>
  "马上发布，并和你各种角色的小伙伴们分享应用";
export const BUILD_MY_FIRST_APP = () => "我来部署";
export const ONBOARDING_INTRO_FOOTER = () => "让我们开始构建你的第一个应用吧";
export const START_TUTORIAL = () => "开始";
export const WELCOME_TO_APPSMITH = () => "欢迎来到 Appsmith ！";
export const QUERY_YOUR_DATABASE = () =>
  "查询你的数据库或 API，使用 JS 构建动态查询";
export const SIGNPOSTING_INFO_MENU = {
  documentation: () => "打开文档",
};

//Statusbar
export const ONBOARDING_STATUS_STEPS_FIRST = () => "第一步：添加数据源";
export const ONBOARDING_STATUS_STEPS_FIRST_ALT = () => "下一步：添加数据源";
export const ONBOARDING_STATUS_STEPS_SECOND = () => "下一步：创建查询";
export const ONBOARDING_STATUS_STEPS_THIRD = () => "下一步：添加组件";
export const ONBOARDING_STATUS_STEPS_THIRD_ALT = () => "第一步：添加组件";
export const ONBOARDING_STATUS_STEPS_FOURTH = () => "下一步：给组件绑定数据";
export const ONBOARDING_STATUS_STEPS_FIVETH = () => "下一步：发布你的应用";
export const ONBOARDING_STATUS_STEPS_SIXTH = () => "完成 🎉";
export const ONBOARDING_STATUS_GET_STARTED = () => "开始";

//Tasks
//1. datasource
export const ONBOARDING_TASK_DATASOURCE_HEADER = () =>
  "开始添加你的第一个数据源";
export const ONBOARDING_TASK_DATASOURCE_BODY = () =>
  "添加数据源能够让你的应用发挥威力，如果你手上没有数据源也不用担心，我们为你准备了一个样例数据库";
export const ONBOARDING_TASK_DATASOURCE_BUTTON = () => "+ 添加数据源";
export const ONBOARDING_TASK_DATASOURCE_FOOTER_ACTION = () => "添加组件";
export const ONBOARDING_TASK_DATASOURCE_FOOTER = () => "第一步";
//2. query
export const ONBOARDING_TASK_QUERY_HEADER = () => "接下来，创建一个查询";
export const ONBOARDING_TASK_QUERY_BODY = () =>
  "非常棒！下一步就是用你的数据源创建一个查询了";
export const ONBOARDING_TASK_QUERY_BUTTON = () => "+ 创建查询";
export const ONBOARDING_TASK_QUERY_FOOTER_ACTION = () => "添加组件";
//2. widget
export const ONBOARDING_TASK_WIDGET_HEADER = () =>
  "然后，添加一个组件来展示数据";
export const ONBOARDING_TASK_WIDGET_BODY = () =>
  "非常棒！下一步就是添加一个组件把你的数据展示出来";
export const ONBOARDING_TASK_WIDGET_BUTTON = () => "添加组件";
export const ONBOARDING_TASK_WIDGET_FOOTER_ACTION = () => "发布你的应用";
export const ONBOARDING_TASK_FOOTER = () => "或者，你也可以";

export const USE_SNIPPET = () => "代码片段";
export const SNIPPET_TOOLTIP = () => "搜索代码片段";

//Welcome page
export const WELCOME_HEADER = () => "欢迎！";
export const WELCOME_BODY = () => "在创建应用之前，请先让我们给你创建一个账号";
export const WELCOME_ACTION = () => "开始吧";

// API Editor
export const API_EDITOR_TAB_TITLES = {
  HEADERS: () => "请求头",
  PARAMS: () => "参数",
  BODY: () => "请求体",
  PAGINATION: () => "分页",
  AUTHENTICATION: () => "鉴权",
  SETTINGS: () => "设置",
};
export const ACTION_EXECUTION_MESSAGE = (actionType: string) =>
  `正在请求 ${actionType}`;
export const ACTION_EXECUTION_CANCEL = () => "取消请求";

export const WELCOME_FORM_HEADER = () => "让我们更好的了解你！";
export const WELCOME_FORM_FIRST_NAME = () => "名字";
export const WELCOME_FORM_LAST_NAME = () => "姓氏";
export const WELCOME_FORM_EMAIL_ID = () => "邮箱";
export const WELCOME_FORM_CREATE_PASSWORD = () => "创建密码";
export const WELCOME_FORM_VERIFY_PASSWORD = () => "校验密码";
export const WELCOME_FORM_ROLE_DROPDOWN = () => "你担任何种角色？";
export const WELCOME_FORM_ROLE_DROPDOWN_PLACEHOLDER = () => "- 选择一个角色 -";
export const WELCOME_FORM_ROLE = () => "角色";
export const WELCOME_FORM_CUSTOM_USE_CASE = () => "使用场景";
export const WELCOME_FORM_USE_CASE = () => "请告诉我们你的使用场景";
export const WELCOME_FORM_USE_CASE_PLACEHOLDER = () => "- 选择一个使用场景 -";
export const WELCOME_FORM_DATA_COLLECTION_HEADER = () => "分享使用数据";
export const WELCOME_FORM_DATA_COLLECTION_BODY = () =>
  "分享匿名的使用数据来帮助我们提升产品质量";
export const WELCOME_FORM_DATA_COLLECTION_LINK = () => "看看分享了哪些数据";
export const WELCOME_FORM_DATA_COLLECTION_LABEL_ENABLE = () =>
  "分享数据让 Appsmith 变得更好！";
export const WELCOME_FORM_DATA_COLLECTION_LABEL_DISABLE = () =>
  "不分享任何数据";
export const WELCOME_FORM_NEWLETTER_HEADER = () => "保持联系";
export const WELCOME_FORM_NEWLETTER_LABEL = () =>
  "获取我们的更新，放心，我们不会给你发垃圾邮件的";
export const WELCOME_FORM_SUBMIT_LABEL = () => "创建你的第一个应用";

//help tooltips
export const ACCOUNT_TOOLTIP = () => "你的账号";
export const RENAME_APPLICATION_TOOLTIP = () => "重命名应用";
export const LOGO_TOOLTIP = () => "回到首页";
export const ADD_PAGE_TOOLTIP = () => "新建页面";
export const ADD_DATASOURCE_TOOLTIP = () => "添加数据源或者创建新的查询";
export const ADD_WIDGET_TOOLTIP = () => "查找、添加组件";
export const HELP_RESOURCE_TOOLTIP = () => "帮助资源";
export const COPY_ELEMENT = () => "复制元素";
export const SHOW_TEMPLATES = () => "新建查询";
export const LAYOUT_DROPDOWN_TOOLTIP = () => "选择你的应用宽度";
export const DEPLOY_BUTTON_TOOLTIP = () => "发布最新应用";
export const SHARE_BUTTON_TOOLTIP = () => "邀请你的团队到 Appsmith";
export const SHARE_BUTTON_TOOLTIP_WITH_USER = (length: number) => () =>
  `和 ${length} 位小伙伴共享`;
export const DEBUGGER_TOOLTIP = () => "打开调试器";
export const PAGE_PROPERTIES_TOOLTIP = () => "页面配置";
export const CLEAR_LOG_TOOLTIP = () => "清空日志";
export const ADD_JS_ACTION = () => "新建脚本";
export const ENTITY_MORE_ACTIONS_TOOLTIP = () => "更多操作";
export const NOTIFICATIONS_TOOLTIP = () => "通知";

// Navigation Menu
export const DEPLOY_MENU_OPTION = () => "发布";
export const CURRENT_DEPLOY_PREVIEW_OPTION = () => "最新发布版本";
export const CONNECT_TO_GIT_OPTION = () => "连接到 Git 仓库";
//
export const GO_TO_PAGE = () => "跳转到页面";
export const DEFAULT_PAGE_TOOLTIP = () => "默认页面";
export const HIDDEN_TOOLTIP = () => "隐藏";
export const CLONE_TOOLTIP = () => "复制";
export const DELETE_TOOLTIP = () => "删除";
export const SETTINGS_TOOLTIP = () => "设置";

//settings
export const ADMIN_SETTINGS = () => "管理员设置";
export const RESTART_BANNER_BODY = () => "请稍等，马上就好了";
export const RESTART_BANNER_HEADER = () => "正在帮你重启服务...";
export const RESTART_ERROR_BODY = () => "你可以试着手动重启服务来让更改生效";
export const RESTART_ERROR_HEADER = () => "重启失败";
export const RETRY_BUTTON = () => "重试";
export const INFO_VERSION_MISMATCH_FOUND_RELOAD_REQUEST = () =>
  "发现 Appsmith 新版本，请刷新页面试试";
export const TEST_EMAIL_SUCCESS = (email: string) => () =>
  `测试邮件已发送完毕，请前往 ${email} 查收`;
export const TEST_EMAIL_SUCCESS_TROUBLESHOOT = () => "疑难杂症";
export const TEST_EMAIL_FAILURE = () => "测试邮件发送失败";

export const ADMIN_SETTINGS_EMAIL_WARNING = () =>
  "请确保已经正确配置了 SMTP 邮件服务，它会被用来重置密码和校验邮箱";
export const DISCONNECT_AUTH_ERROR = () => "不能断开唯一已连接的鉴权方式";
export const MANDATORY_FIELDS_ERROR = () => "必填字段不能为空";
export const FORM_LOGIN_DESC = () => "开启账号密码登录";
export const GOOGLE_AUTH_DESC = () => "开启 Google (OAuth 2.0) 单点登录 (SSO)";
export const GITHUB_AUTH_DESC = () => "开启 GitHub (OAuth 2.0) 单点登录 (SSO)";
export const SAML_AUTH_DESC = () => "开启 SAML2 登录";
export const OIDC_AUTH_DESC = () => "开启 OIDC 登录";
export const SAVE_BUTTON = () => "保存";
export const SAVE_AND_RESTART_BUTTON = () => "保存并重启";
export const SAVE_AND_REFRESH_BUTTON = () => "保存并刷新";
export const RESET_BUTTON = () => "重置";
export const BUSINESS_TAG = () => "商业版";
export const ENTERPRISE_TAG = () => "企业版";

// Upgrade pages begin
export const AVAILABLE_ON_BUSINESS = () => "商业版可用";
export const EXCLUSIVE_TO_BUSINESS = (featureName: string) =>
  `${featureName}在商业版中可用`;
export const AVAILABLE_ON_ENTERPRISE = () => "企业版可用";
// Upgrade pages end

// Audit logs begin
export const AUDIT_LOGS = () => "审计日志";
export const TRY_AGAIN_WITH_YOUR_FILTER = () => "重新过滤";

// Audit logs Upgrade page begin
export const INTRODUCING = (featureName: string) => `介绍${featureName}`;
export const AUDIT_LOGS_UPGRADE_PAGE_SUB_HEADING = () =>
  "记录带时间戳的系统事件，并且支持通过事件类型、用户、资源ID和时间过滤，展开查看事件详情";
export const SECURITY_AND_COMPLIANCE = () => "安全合规";
export const SECURITY_AND_COMPLIANCE_DETAIL1 = () =>
  "主动降低错误配置权限带来的风险，从关键的安全事件中回滚更改，并保持对合规性策略的检查";
export const SECURITY_AND_COMPLIANCE_DETAIL2 = () =>
  "导出到主流合规工具，敬请期待";
export const DEBUGGING = () => "定位问题";
export const DEBUGGING_DETAIL1 = () =>
  "使用用户和资源 ID 过滤的事件时间线进行问题调试，将它们与终端用户和应用开发人员的操作相关联，追溯到应用最后的正常状态";
export const INCIDENT_MANAGEMENT = () => "事故管理";
export const INCIDENT_MANAGEMENT_DETAIL1 = () =>
  "追溯事故起因，查清变化，并运行 RCA 来补救";
// Audit logs Upgrade page end
// Audit logs end

// Access control upgrade page begin
export const GRANULAR_ACCESS_CONTROL_FOR_TEAMS = () => "团队访问控制";
export const ACCESS_CONTROL_UPGRADE_PAGE_SUB_HEADING = () =>
  "控制应用中所有资源的权限， 通过属性管理权限，使用权限和团队来定义访问级别";
export const SECURITY_APPS_LEAST_PRIVILEGE = () => "最小特权原则保护应用";
export const SECURITY_APPS_LEAST_PRIVILEGE_DETAIL1 = () =>
  `用最小特权原则来创建角色，例如：默认将 <span>只读权限</span> 赋给团队<span>（市场部）</span>的 <span>creators_Execute查询</span>`;
export const PREVENT_ACCIDENTAL_DAMAGE = () => "防止数据意外损害";
export const PREVENT_ACCIDENTAL_DAMAGE_DETAIL1 = () =>
  `为团队分配编辑和删除权限，然后按粒度进行修改，这样非团队用户就不能随意删除表和数据记录`;
export const RESTRICT_PUBLIC_EXPOSURE = () => "限制暴露敏感数据";
export const RESTRICT_PUBLIC_EXPOSURE_DETAIL1 = () =>
  "主动禁止非管理员或非超级管理员用户公开分享你的应用程序或将应用数据导出到你的安全范围之外";
export const ACCESS_CONTROL_UPGRADE_PAGE_FOOTER = () =>
  "升级到商业版，解锁细粒度权限控制、审计日志、SSO等高级功能，极大提升系统的安全性、可靠性";
// Access control upgrade page end

// Provisioning upgrade page begin
export const USER_PROVISIONING_FOR_ENTERPRISES = () =>
  "使用你的身份服务来管理 Appsmith 用户";
export const PROVISIONING_UPGRADE_PAGE_SUB_HEADING = () =>
  `统一添加、删除 Appsmith 用户，同步现有团队到 Appsmith`;
export const PROVISION_DEPROVISION_USERS = () => "从你的 IdP 中装载、卸载用户";
export const PROVISION_DEPROVISION_USERS_DETAIL1 = () =>
  `通过你的 IdP SCIM 协议来控制用户认证和访问权限<div>&nbsp;</div><div><span style="font-style: italic;font-weight: normal;">更多协议敬请期待</span></div>`;
export const AUTO_GROUP_SYNC = () => "自动同步团队";
export const AUTO_GROUP_SYNC_DETAIL1 = () =>
  `从你的 IdP 自动同步团队到 Appsmith，轻松控制团队访问权限`;
export const PROVISIONING_UPGRADE_PAGE_FOOTER = () =>
  "升级到企业版，解锁细粒度权限控制、审计日志、SSO等高级功能，极大提升系统的安全性、可靠性";
// Provisioning upgrade page end

//
export const WELCOME_FORM_NON_SUPER_USER_ROLE_DROPDOWN = () =>
  "请告诉我们你的工作角色是？";
export const WELCOME_FORM_NON_SUPER_USER_ROLE = () => "角色";
export const WELCOME_FORM_NON_SUPER_USER_USE_CASE = () =>
  "请问你打算用 Appsmith 来做什么应用呢？";
export const WELCOME_FORM_NON_SUPER_USER_PROFICIENCY_LEVEL = () =>
  "请问你的开发经验如何？";

export const WELCOME_FORM_ROLE_ERROR_MESSAGE = () => "请输入角色";
export const WELCOME_FORM_PROFICIENCY_ERROR_MESSAGE = () =>
  "请选择开发经验级别";
export const WELCOME_FORM_USE_CASE_ERROR_MESSAGE = () => "请选择用途";

export const WELCOME_FORM_CUSTOM_USE_CASE_ERROR_MESSAGE = () => "请输入用途";

export const WELCOME_FORM_EMAIL_ERROR_MESSAGE = () => "输入有效邮箱地址";

export const WELCOME_FORM_STRONG_PASSWORD_ERROR_MESSAGE = () => "请输入强密码";

export const WELCOME_FORM_GENERIC_ERROR_MESSAGE = () => "必填";

export const WELCOME_FORM_PASSWORDS_NOT_MATCHING_ERROR_MESSAGE = () =>
  "密码不正确";

export const QUERY_CONFIRMATION_MODAL_MESSAGE = () => `确定运行吗？`;
export const ENTITY_EXPLORER_TITLE = () => "导航";
export const MULTI_SELECT_PROPERTY_PANE_MESSAGE = () =>
  `选择组件查看它的各种属性`;
export const WIDGET_DEPRECATION_MESSAGE = (widgetName: string) =>
  `拖拽最新版本的${widgetName}组件来避免不必要的兼容问题`;

export const LOCK_ENTITY_EXPLORER_MESSAGE = () => `固定侧边栏`;
export const CLOSE_ENTITY_EXPLORER_MESSAGE = () => `关闭侧边栏`;
export const JS_TOGGLE_DISABLED_MESSAGE = "清空字段回退";
export const PROPERTY_PANE_EMPTY_SEARCH_RESULT_MESSAGE = "没有发现任何属性";
export const PROPERTY_SEARCH_INPUT_PLACEHOLDER = "搜索组件属性";
export const EXPLORER_BETA_ENTITY = () => "尝鲜版";
export const BINDING_WIDGET_WALKTHROUGH_TITLE = () => "组件属性";
export const BINDING_WIDGET_WALKTHROUGH_DESC = () =>
  `我们已经帮你设置好了表格的数据字段，右侧属性面板是配置组件属性的核心区域，你可以随时修改它`;

// API Pane
export const API_PANE_NO_BODY = () => "当前请求没有请求体";
export const API_PANE_AUTO_GENERATED_HEADER = () =>
  "Appsmith 会根据 API 的请求体类型自动生成请求头 content-type，如果需要覆盖它，请新建 content-type 请求头字段";
export const API_PANE_DUPLICATE_HEADER = (headerName: string) =>
  `这个重复的请求头会被你添加的 ${headerName} 请求头覆盖`;

export const TABLE_WIDGET_TOTAL_RECORD_TOOLTIP = () =>
  "保存数据表的总行数，用来在分页的时候计算是否还有下一页/上一页";
export const CREATE_DATASOURCE_TOOLTIP = () => "添加新数据源";
export const ADD_QUERY_JS_TOOLTIP = () => "新建查询/脚本";
export const LIST_WIDGET_V2_TOTAL_RECORD_TOOLTIP = () =>
  "计算总数据行数，这个可以帮助我们计算出应该展示的页数";

// Add datasource
export const GENERATE_APPLICATION_TITLE = () => "新建页面";
export const GENERATE_APPLICATION_DESCRIPTION = () =>
  "用你的数据库表快速生成一个增删改查页面";
export const DELETE_WORKSPACE_SUCCESSFUL = () => "应用组删除成功";
// theming
export const CHANGE_APP_THEME = (name: string) => `已切换为 ${name} 风格主题`;
export const SET_DEFAULT_SELECTED_THEME = (name: string) =>
  `找不到自定义主题，切换到 ${name} 风格主题`;
export const SAVE_APP_THEME = (name: string) => `风格主题 ${name} 已保存`;
export const DELETE_APP_THEME = (name: string) => `风格主题 ${name} 已删除`;
export const DELETE_APP_THEME_WARNING = () =>
  `确定删除这个风格主题吗？这个操作不可恢复`;
export const APP_THEME_BETA_CARD_HEADING = () => `🎨 修改应用风格`;
export const APP_THEME_BETA_CARD_CONTENT = () =>
  `自定义全局样式风格，后面对所有组件进行支持`;

// echat theme
export const CHANGE_ECHART_THEME = (name: string) =>
  `Echart主题已切换为 ${name} 风格主题`;

export const UPGRADE_TO_EE = (authLabel: string) =>
  `你好，我想升级来使用 ${authLabel} 鉴权`;
export const UPGRADE_TO_EE_FEATURE = (feature: string) =>
  `你好，我想升级来使用 ${feature} 功能`;
export const UPGRADE_TO_EE_GENERIC = () => `你好，我想升级`;
export const ADMIN_AUTH_SETTINGS_TITLE = () => "选择鉴权方式";
export const ADMIN_AUTH_SETTINGS_SUBTITLE = () => "选择一个鉴权协议";
export const DANGER_ZONE = () => "危险操作";
export const DISCONNECT_AUTH_METHOD = () => "断开连接";
export const DISCONNECT_CONFIRMATION = () => "你确定吗？";

// Branding
export const ADMIN_BRANDING_SETTINGS_TITLE_UPGRADE = () =>
  "为你的平台自定义品牌";
export const ADMIN_BRANDING_SETTINGS_SUBTITLE_UPGRADE = () =>
  "自由定制你的 LOGO 和品牌色，如果需要使用此功能请升级到商业版";
export const ADMIN_BRANDING_COLOR_TOOLTIP = () =>
  `当你选择主色后，我们会自动为你生成辅助色和强调色，你也可以随时调整它们`;
export const ADMIN_BRANDING_LOGO_SIZE_ERROR = () => `上传文件大小不能超过 2MB`;
export const ADMIN_BRANDING_LOGO_DIMENSION_ERROR = () =>
  `Logo 的高度必须大于 256px`;
export const ADMIN_BRANDING_LOGO_FORMAT_ERROR = () =>
  `上传文件仅支持 .SVG, .PNG, 和 .JPG 格式`;
export const ADMIN_BRANDING_LOGO_REQUIREMENT = () =>
  `仅限 .SVG, .PNG, 或 .JPG • 最大 2MB`;
export const ADMIN_BRANDING_FAVICON_DIMENSION_ERROR = () =>
  `上传文件最大尺寸为 32 X 32 像素`;
export const ADMIN_BRANDING_FAVICON_SIZE_ERROR = () => `上传文件必须小于 2MB`;
export const ADMIN_BRANDING_FAVICON_FORMAT_ERROR = () =>
  `上传文件仅支持 .ICO, .PNG, 和 .JPG 格式`;
export const ADMIN_BRANDING_FAVICON_REQUIREMENT = () =>
  `仅限 .ICO, .PNG, 或 .JPG • 最大尺寸 32 X 32`;
export const ADMIN_BRANDING_COLOR_TOOLTIP_PRIMARY = () =>
  `用于按钮、链接或其他交互组件的颜色`;
export const ADMIN_BRANDING_COLOR_TOOLTIP_BACKGROUND = () => `用于背景颜色`;
export const ADMIN_BRANDING_COLOR_TOOLTIP_HOVER = () => `用于按钮 hover 颜色`;
export const ADMIN_BRANDING_COLOR_TOOLTIP_FONT = () => `用于按钮文字颜色`;
export const ADMIN_BRANDING_COLOR_TOOLTIP_DISABLED = () =>
  `用于禁用按钮背景颜色`;
export const ADMIN_BRANDING_UPGRADE_INTERCOM_MESSAGE = () =>
  `你好，我想试试 Appsmith 商业版 😀`;

// Guided tour
// -- STEPS ---
export const STEP_ONE_TITLE = () =>
  "第一步，查询数据库，现在我们准备从 Postgres 数据库中查询客户数据";
export const STEP_ONE_SUCCESS_TEXT = () =>
  "非常棒！你已经成功从数据库中查询到数据，你可以在下面查看数据详情";
export const STEP_ONE_BUTTON_TEXT = () => "继续下一步";
export const STEP_TWO_TITLE = () =>
  "用表格组件把数据展示出来，请选择我们已经为你添加的表格组件。";
export const STEP_THREE_TITLE = () => "用表格组件把数据展示出来";
export const STEP_THREE_SUCCESS_TEXT = () =>
  "干得漂亮！现在表格组件已经展示了查询出来的数据，你可以在任意输入框内使用 {{ }} 给组件绑定数据";
export const STEP_THREE_SUCCESS_BUTTON_TEXT = () => "继续下一步";
export const STEP_FOUR_TITLE = () => "让我们构建一个表单来更新用户数据";
export const STEP_FOUR_HINT_BUTTON_TEXT = () => "继续";
export const STEP_FOUR_SUCCESS_TEXT = () =>
  "太棒了！你已经把表格的选中行数据绑定到输入框了，输入框会一直显示选中行的信息";
export const STEP_FOUR_SUCCESS_BUTTON_TEXT = () => "继续下一步";
export const STEP_FIVE_TITLE = () => "在更新表单中绑定所有的输入字段";
export const STEP_FIVE_HINT_TEXT = () =>
  `现在让我们把表格选中行数据绑定到容器中的其他组件`;
export const STEP_FIVE_SUCCESS_TEXT = () =>
  "干的漂亮！现在所有的输入框都已经绑定了表格的选中行数据";
export const STEP_FIVE_SUCCESS_BUTTON_TEXT = () => "继续下一步";
export const STEP_SIX_TITLE = () => "添加更新按钮来触发查询";
export const STEP_SIX_SUCCESS_TEXT = () =>
  "完美！你的更新按钮已经准备好触发更新了";
export const STEP_SIX_SUCCESS_BUTTON_TEXT = () => "继续下一步";
export const STEP_SEVEN_TITLE = () => "让按钮绑定触发 updateCustomerInfo 查询";
export const STEP_EIGHT_TITLE = () => "触发更新后，重新查询更新后的用户数据";
export const STEP_EIGHT_SUCCESS_TEXT = () =>
  "完美！现在你已经成功构建了一个可以查询、更新用户数据的应用";
export const STEP_NINE_TITLE = () => "最后一步：测试并且发布你的应用";
export const CONTINUE = () => "继续";
export const PROCEED_TO_NEXT_STEP = () => "继续下一步";
export const PROCEED = () => "继续";
export const COMPLETE = () => "完成";
// -- Modal --
export const DEVIATION = () => "退出教程";
export const END_CONFIRMATION = () => "确定结束吗？";
export const CANCEL_DIALOG = () => "取消";
// -- End Tutorial --
export const END_TUTORIAL = () => "结束";
// -- Intro content --
export const TITLE = () => "我们会在这个教程中创建一个查询用户信息的应用";
export const DESCRIPTION = () =>
  "应用包含一个展示用户数据的表格，还有一个可以更新表格数据的表单，在开始前你可以试用一下这个应用";
export const BUTTON_TEXT = () => "开始吧";
// -- Rating --
export const RATING_TITLE = () =>
  "恭喜！你已经用 Appsmith 构建出了你的第一个应用！";
export const RATING_DESCRIPTION = () =>
  "现在你可以邀请其他小伙伴加入到这个应用";
export const RATING_TEXT = () => "体验评分";
// -- End Message --
export const END_TITLE = () => "下一步是什么？开始构建你自己的应用吧";
export const END_DESCRIPTION = () => "看看各个查询、组件的属性配置吧";
export const END_BUTTON_TEXT = () => "开始构建应用";

export const CONTEXT_EDIT_NAME = () => "编辑名称";
export const CONTEXT_SHOW_BINDING = () => "可绑定变量";
export const CONTEXT_MOVE = () => "移动到页面";
export const CONTEXT_COPY = () => "复制到页面";
export const CONTEXT_DELETE = () => "删除";
export const CONFIRM_CONTEXT_DELETE = () => "确定删除吗？";
export const CONFIRM_CONTEXT_DELETING = () => "正在删除";
export const CONTEXT_NO_PAGE = () => "暂无页面";
export const CONTEXT_REFRESH = () => "刷新";
export const CONTEXT_CLONE = () => "复制页面";
export const CONTEXT_SETTINGS = () => "设置";
export const CONTEXT_SET_AS_HOME_PAGE = () => "设置为主页";
export const PAGE = () => "页面";
export const PAGES = () => "页面";

// Entity explorer
export const ADD_DATASOURCE_BUTTON = () => "添加数据源";
export const ADD_WIDGET_BUTTON = () => "添加组件";
export const ADD_QUERY_JS_BUTTON = () => "添加查询/脚本";
export const EMPTY_WIDGET_MAIN_TEXT = () => "暂无组件";
export const EMPTY_WIDGET_BUTTON_TEXT = () => "添加组件";
export const EMPTY_QUERY_JS_MAIN_TEXT = () => "暂无查询/脚本";
export const EMPTY_QUERY_JS_BUTTON_TEXT = () => "添加查询/脚本";
export const EMPTY_DATASOURCE_MAIN_TEXT = () => "暂无数据源";
export const EMPTY_DATASOURCE_BUTTON_TEXT = () => "添加数据源";
export const SEARCH_DATASOURCES = () => "搜索数据源";

// Templates
export const MORE = () => "更多";
export const SHOW_LESS = () => "收起";
export const CHOOSE_WHERE_TO_FORK = () => "选择把模板克隆到哪里";
export const SELECT_WORKSPACE = () => "选择应用组";
export const FORK_TEMPLATE = () => "克隆模板";
export const TEMPLATES = () => "模板";
export const FORK_THIS_TEMPLATE = () => "使用这个模板";
export const COULDNT_FIND_TEMPLATE = () => "找不到你想要的模板吗？";
export const COULDNT_FIND_TEMPLATE_DESCRIPTION = () =>
  "你可以在 github 上给我们提 issue ，告诉我们你想要什么模板";
export const REQUEST_TEMPLATE = () => "模板诉求";
export const SEARCH_TEMPLATES = () => "搜索模板";
export const INTRODUCING_TEMPLATES = () => "介绍模板";
export const TEMPLATE_NOTIFICATION_DESCRIPTION = () =>
  "使用这些模板更快的学习构建应用";
export const GO_BACK = () => "返回";
export const OVERVIEW = () => "概览";
export const FUNCTION = () => "功能";
export const INDUSTRY = () => "行业";
export const DATASOURCES = () => "数据源";
export const NOTE = () => "注意：";
export const NOTE_MESSAGE = () => "你可以添加你自己的数据源";
export const WIDGET_USED = () => "用到的组件";
export const SIMILAR_TEMPLATES = () => "类似模板";
export const VIEW_ALL_TEMPLATES = () => "查看所有模板";
export const FILTERS = () => "过滤";
export const TEMPLATE_CARD_TITLE = () => "从模板开始";
export const TEMPLATE_CARD_DESCRIPTION = () => "选择模板页面创建应用";
export const FILTER_SELECTALL = () => "全选";
export const FILTER_SELECT_PAGES = () => "选择页面";
export const FORKING_TEMPLATE = () => "正在克隆模板";
export const FETCHING_TEMPLATES = () => "正在加载模板详情";
export const FETCHING_TEMPLATE_LIST = () => "正在加载模板列表";

export const TEMPLATES_BACK_BUTTON = () => "返回模板";
export const IMAGE_LOAD_ERROR = () => "图片加载失败";

export const REDIRECT_URL_TOOLTIP = () =>
  "这个地址用来配置你的身份认证回调/重定向";
export const ENTITY_ID_TOOLTIP = () => "这个地址用来配置你的身份认证的实体 ID";

export const FORK_APP_MODAL_LOADING_TITLE = () => "正在拉取应用组...";
export const FORK_APP_MODAL_EMPTY_TITLE = () => "没有可用应用组";
export const FORK_APP_MODAL_SUCCESS_TITLE = () => "选择把应用复制到哪里";
export const FORK = () => `复制`;

export const CLEAN_URL_UPDATE = {
  name: () => "更新地址",
  shortDesc: () =>
    "你应用的所有地址都会被更新为一个更易读的格式，包括应用名称和页面名称",
  description: [
    () =>
      "你应用的所有地址都会被更新成新的形式，这样会使你的应用更容易被找到、更容易被人记住",
    (url: string) =>
      `当前应用地址更新为：<br /><code style="line-break: anywhere; padding: 2px 4px; line-height: 22px">${url}</code>`,
  ],
  disclaimer: () =>
    "引用了 <strong>appsmith.URL.fullpath</strong> 和 <strong>appsmith.URL.pathname</strong> 的属性会有显示出变化",
};

export const MEMBERS_TAB_TITLE = (
  length: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFreeInstance?: boolean,
) => `成员 (${length})`;
export const SEARCH_USERS = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFreeInstance?: boolean,
) => `搜索用户`;

export const CREATE_PAGE = () => "新建空白页面";
export const CANVAS_NEW_PAGE_CARD = () => "新建页面";
export const GENERATE_PAGE = () => "用数据库自动生成页面";
export const GENERATE_PAGE_DESCRIPTION = () =>
  "用数据库数据自动生成一个增删改查页面";
export const ADD_PAGE_FROM_TEMPLATE = () => "添加模板页面";
export const INVALID_URL = () => "请输入有效 URL，例如 https://example.com";
export const SAVE_OR_DISCARD_DATASOURCE_WARNING = () =>
  `离开页面后未保存的数据将会丢失，离开前请先保存信息`;

export const APP_SETTINGS_PANE_HEADER = () => "设置";
export const APP_SETTINGS_CLOSE_TOOLTIP = () => "关闭设置面板";

export const GENERAL_SETTINGS_SECTION_HEADER = () => "通用";
export const GENERAL_SETTINGS_SECTION_CONTENT_HEADER = () => "通用设置";
export const GENERAL_SETTINGS_SECTION_HEADER_DESC = () =>
  "应用名称、图标和分享";
export const GENERAL_SETTINGS_APP_NAME_LABEL = () => "应用名称";
export const GENERAL_SETTINGS_NAME_EMPTY_MESSAGE = () => "应用名称不能为空";
export const GENERAL_SETTINGS_NAME_SPECIAL_CHARACTER_ERROR = () =>
  "只能输入字母、数字、'-'、'('、')' 等字符";
export const GENERAL_SETTINGS_APP_ICON_LABEL = () => "应用图标";

export const THEME_SETTINGS_SECTION_HEADER = () => "主题";
export const THEME_SETTINGS_SECTION_CONTENT_HEADER = () => "主题设置";
export const THEME_SETTINGS_SECTION_HEADER_DESC = () => "设置主题、颜色、字体";

export const PAGE_SETTINGS_SECTION_HEADER = () => "页面设置";
export const PAGE_SETTINGS_SECTION_CONTENT_HEADER = () => "设置";
export const PAGE_SETTINGS_PAGE_NAME_LABEL = () => "页面名称";
export const PAGE_SETTINGS_NAME_EMPTY_MESSAGE = () => "页面名称不能为空";
export const PAGE_SETTINGS_NAME_SPECIAL_CHARACTER_ERROR = () =>
  "只能输入字母、数字或 '-'";
export const PAGE_SETTINGS_PAGE_URL_LABEL = () => "修改页面 URL";
export const PAGE_SETTINGS_PAGE_URL_VERSION_UPDATE_1 = () => "请";
export const PAGE_SETTINGS_PAGE_URL_VERSION_UPDATE_2 = () => "更新";
export const PAGE_SETTINGS_PAGE_URL_VERSION_UPDATE_3 = () =>
  "你的应用 URL 到新的可读格式";
export const PAGE_SETTINGS_SHOW_PAGE_NAV = () => "显示菜单导航";
export const PAGE_SETTINGS_SHOW_PAGE_NAV_TOOLTIP = () =>
  "当前页面是否显示应用菜单导航";
export const PAGE_SETTINGS_SET_AS_HOMEPAGE = () => "设为主页";
export const PAGE_SETTINGS_SET_AS_HOMEPAGE_TOOLTIP = () =>
  "当前页面已经是主页，你可以将其他页面设为主页";
export const PAGE_SETTINGS_SET_AS_HOMEPAGE_TOOLTIP_NON_HOME_PAGE = () =>
  "将这页设置为主页";
export const PAGE_SETTINGS_ACTION_NAME_CONFLICT_ERROR = (name: string) =>
  `${name} 已经被占用`;

export const CODE_EDITOR_LOADING_ERROR = (message?: string) =>
  `代码编辑器加载失败${message ? `: ${message}` : ""}`;

export const UPDATE_VIA_IMPORT_SETTING = {
  settingHeader: () => "导入应用",
  settingDesc: () => "导入应用文件更新应用",
  settingLabel: () => "导入",
  settingContent: () => "这个操作会覆盖现有的应用，请谨慎操作",
  settingActionButtonTxt: () => "导入",
  disabledForGit: () =>
    "这个特性对已连接到 git 的应用无效，对于已连接 git 的应用，请通过 git 来同步最新应用",
};

export const IN_APP_EMBED_SETTING = {
  applicationUrl: () => "应用地址",
  allowEmbeddingLabel: () => "支持嵌入",
  allowEmbeddingTooltip: () => "应用可以嵌入到任何域名下，包括那些恶意网站",
  forkApplicationConfirmation: {
    title: () => "允许克隆应用吗？",
    body: () => "允许开发者把应用克隆到他们的应用组",
    cancel: () => "取消",
    confirm: () => "允许克隆",
  },
  copy: () => "复制",
  copied: () => "已复制",
  limitEmbeddingLabel: () => "限制嵌入",
  limitEmbeddingTooltip: () => "应用只能嵌入到特定的域名下",
  disableEmbeddingLabel: () => "不能嵌入",
  disableEmbeddingTooltip: () => "应用不能被嵌入到任何域名下",
  embed: () => "嵌入",
  embedSnippetTitle: () => "复制嵌入代码",
  change: () => "修改",
  copiedEmbedCode: () => "嵌入代码已复制",
  embedSize: () => "嵌入宽高",
  previewEmbeddedApp: () => "预览嵌入应用",
  sectionHeader: () => "分享 & 嵌入",
  sectionContentHeader: () => "分享",
  sectionHeaderDesc: () => "共享应用，嵌入属性",
  showNavigationBar: () => "显示菜单导航",
  forkContentHeader: () => "克隆",
  forkLabel: () => "允许克隆",
  forkLabelTooltip: () => "克隆能够让开发者把你的应用复制到他们的应用组",
  upgradeHeading: () =>
    "社区版本支持嵌入公开应用，如果你想公开应用请联系你的管理员",
  upgradeHeadingForInviteModal: () => "公开应用",
  upgradeSubheadingForInviteModal: () =>
    "查看共享设置，公开应用来把它轻松嵌入到其他老旧的系统里",
  privateAppsText: () => "私有应用",
  rampSubtextModal: () => "商业版支持嵌入私有应用，并且通过 SSO 进行鉴权",
  rampSubtextSidebar: () => "嵌入私有应用并通过 SSO 鉴权，试试",
  rampLinktext: () => "试试商业版",
  rampLinktextvariant2: () => "商业版",
  upgradeContent: () => "私有嵌入不可用",
  appsmithBusinessEdition: () => "Appsmith 商业版",
  secondaryHeadingForAppSettings: () =>
    "公开你的应用让它能够嵌入到其他老旧的系统里去",
  secondaryHeading: () =>
    "社区版本支持嵌入公开应用，如果你想公开应用请联系你的管理员",
};

export const APP_NAVIGATION_SETTING = {
  sectionHeader: () => "导航",
  sectionHeaderDesc: () => "自定义导航栏",
  showNavbarLabel: () => "查看导航栏",
  orientationLabel: () => "方向",
  navStyleLabel: () => "风格",
  positionLabel: () => "位置",
  itemStyleLabel: () => "条目样式",
  colorStyleLabel: () => "背景色",
  logoLabel: () => "Logo",
  logoConfigurationLabel: () => "Logo 配置",
  showSignInLabel: () => "展示登录",
  showSignInTooltip: () => "是否在用户未登录的情况下展示登录按钮",
  logoUploadFormatError: () => `只支持上传 .PNG 或 .JPG 格式的文件`,
  logoUploadSizeError: () => `上传文件应该小于 1MB`,
  showLogoLabel: () => "展示 logo",
  showApplicationTitleLabel: () => "展示应用标题",
};

export const LOCK_SIDEBAR_MESSAGE = () => `锁定侧边栏`;
export const CLOSE_SIDEBAR_MESSAGE = () => `关闭侧边栏`;

// Datasource/New query
export const NEW_QUERY_BUTTON_TEXT = () => "新建查询";
export const NEW_API_BUTTON_TEXT = () => "新建 API";
export const GENERATE_NEW_PAGE_BUTTON_TEXT = () => "新建页面";
export const RECONNECT_BUTTON_TEXT = () => "重连";
export const SAVE_BUTTON_TEXT = () => "保存";
export const TEST_BUTTON_TEXT = () => "测试配置";
export const SAVE_AND_AUTHORIZE_BUTTON_TEXT = () => "保存并校验";
export const SAVE_AND_RE_AUTHORIZE_BUTTON_TEXT = () => "保存并重新校验";
export const DISCARD_POPUP_DONT_SAVE_BUTTON_TEXT = () => "不要保存";
export const GSHEET_AUTHORISED_FILE_IDS_KEY = () => "userAuthorizedSheetIds";
export const GOOGLE_SHEETS_INFO_BANNER_MESSAGE = () =>
  "Appsmith 需要访问你的 google 云盘来连接 google sheets";
export const GOOGLE_SHEETS_AUTHORIZE_DATASOURCE = () => "校验数据库";
export const GOOGLE_SHEETS_LEARN_MORE = () => "更多";
export const DATASOURCE_SCHEMA_NOT_AVAILABLE = () => "Schema 不可用";
export const DATASOURCE_INTERCOM_TEXT = () =>
  "需要帮助你创建 Google Sheets 数据源吗？";
export const GOOGLE_SHEETS_ASK_FOR_SUPPORT = () => "需要帮助";
export const GOOGLE_SHEETS_FILE_PICKER_TITLE = () => "选择 Google Sheets";
export const GSHEETS_GENERATE_PAGE_BUTTON = () => "新建页面";
export const GSHEETS_ERR_FETCHING_PREVIEW_DATA = () => "加载数据时出错了";
export const GSHEETS_FETCHING_PREVIEW_DATA = () => "加载数据";
export const GSHEETS_SCHEMA_NO_DATA = () =>
  "无数据，或者表头索引不是从 1 开始的";

//Layout Conversion flow
export const CONVERT = () => "转换布局";
export const BUILD_RESPONSIVE = () => "构建自动布局应用";
export const BUILD_RESPONSIVE_TEXT = () =>
  "Appsmith 会把你的应用界面转换为自动布局，以适应移动端的响应式需求";
export const BUILD_FIXED_LAYOUT = () => "使用固定布局";
export const BUILD_FIXED_LAYOUT_TEXT = () =>
  "Appsmith 会把你的应用界面转换为固定布局，这是默认的布局模式";
export const USE_SNAPSHOT = () => "使用快照";
export const USE_SNAPSHOT_HEADER = () => "使用快照";
export const DISCARD_SNAPSHOT_HEADER = () => "丢弃快照";
export const SAVE_SNAPSHOT = () => "保存当前布局快照 5 天";
export const SAVE_SNAPSHOT_TEXT = () =>
  "我们为页面的当前布局保存了快照，来方便你可以随时回退";
export const CREATE_SNAPSHOT = () => "创建快照";
export const CONVERTING_APP = () => "转换应用";
export const RESTORING_SNAPSHOT = () => "删除修改";
export const REFRESH_THE_APP = () => "刷新应用";
export const CONVERT_ANYWAYS = () => "依然转换";
export const CONVERSION_SUCCESS_HEADER = () => "转换完成";
export const DISCARD_SNAPSHOT_TEXT = () => "你将要丢弃这个快照：";
export const CONVERSION_SUCCESS_TEXT = () =>
  "检查你的所有页面并开始使用新的布局";
export const CONVERSION_WARNING_HEADER = () => "完成，不过需要进行一些调整";
export const CONVERSION_WARNING_TEXT = () =>
  "你可能需要手动调整页面中一些组件的位置";
export const CONVERSION_ERROR_HEADER = () => "转换失败";
export const CONVERSION_ERROR = () => "Appsmith 在转换的时候发生了一些错误";
export const SEND_REPORT = () => "发送报告";
export const CONVERSION_ERROR_TEXT = () => "应用无更新";
export const DROPDOWN_LABEL_TEXT = () => "目标画布尺寸";
export const CONVERSION_WARNING = () => "转换会修改应用布局";
export const SNAPSHOT_LABEL = () => "使用快照将应用转换回原始状态";
export const USE_SNAPSHOT_TEXT = () =>
  "应用会转换回之前的样子，转换后添加的组件、数据源、查询、JS对象以及对它们的修改都会消失";
export const SNAPSHOT_WARNING_MESSAGE = () =>
  "转换后页面的任何修改都不会被保留到快照中";
export const CONVERT_TO_FIXED_TITLE = () => "转换为固定布局";
export const CONVERT_TO_FIXED_BUTTON = () => "转换为固定布局（测试版）";
export const CONVERT_TO_AUTO_TITLE = () => "转换为自动布局";
export const CONVERT_TO_AUTO_BUTTON = () => "转换为自动布局（测试版）";
export const SNAPSHOT_BANNER_MESSAGE = () =>
  "在你丢弃快照前请先确认，使用快照可以让你回退你的应用布局";
export const USE_SNAPSHOT_CTA = () => "使用快照";
export const DISCARD_SNAPSHOT_CTA = () => "丢弃快照";
export const MORE_DETAILS = () => "更多";
export const CONVERSION_ERROR_MESSAGE_HEADER = () => "解决问题请：";
export const CONVERSION_ERROR_MESSAGE_TEXT_ONE = () => "检查你的网络连接";
export const CONVERSION_ERROR_MESSAGE_TEXT_TWO = () =>
  "发送错误报告，让我们可以更好的调试问题";
export const SNAPSHOT_TIME_FROM_MESSAGE = (
  timeSince: string,
  readableDate: string,
) => `快照 ${timeSince} 前 (${readableDate})`;
export const SNAPSHOT_TIME_TILL_EXPIRATION_MESSAGE = (
  timeTillExpiration: string,
) => `你之前布局数据的快照将于 ${timeTillExpiration} 过期失效`;
export const DISCARD = () => "丢弃";
// Alert options and labels for showMessage types
export const ALERT_STYLE_OPTIONS = [
  { label: "信息", value: "'info'", id: "info" },
  {
    label: "成功",
    value: "'success'",
    id: "success",
  },
  { label: "错误", value: "'error'", id: "error" },
  { label: "警告", value: "'warning'", id: "warning" },
];
export const WX_ALERT_STYLE_OPTIONS = [
  { label: "无图标", value: "'none'", id: "info" },
  { label: "成功", value: "'success'", id: "success" },
  { label: "错误", value: "'error'", id: "error" },
  { label: "加载中", value: "'loading'", id: "loading" },
];

export const customJSLibraryMessages = {
  ADD_JS_LIBRARY: () => "添加 JS 工具库",
  REC_LIBRARY: () => "推荐 JS 工具库",
  INSTALLATION_SUCCESSFUL: (accessor: string) =>
    `安装成功！你现在可以在全局使用 ${accessor} 了`,
  INSTALLATION_FAILED: () => "安装失败",
  INSTALLED_ALREADY: (accessor: string) =>
    `已经安装过这个库了，你可以通过 ${accessor} 来使用它`,
  UNINSTALL_FAILED: (name: string) => `不能卸载 ${name}，请稍后重试`,
  UNINSTALL_SUCCESS: (accessor: string) => `${accessor} 卸载成功`,
  LEARN_MORE_DESC: () => "如何使用这个功能？",
  UNSUPPORTED_LIB: () => `不支持这个库`,
  UNSUPPORTED_LIB_DESC: () =>
    `非常抱歉，因为平台限制不能支持这个库，请试试其他的库。`,
  LEARN_MORE: () => `了解更多`,
  REPORT_ISSUE: () => `报告错误`,
  AUTOCOMPLETE_FAILED: (name: string) => `${name} 不支持代码补全`,
  CLIENT_LOAD_FAILED: (url: string) => `脚本拉取失败 ${url}`,
  LIB_OVERRIDE_ERROR: (name: string) =>
    `${name} 已安装，如果想安装另外的版本，请卸载后重新安装。`,
  DEFS_FAILED_ERROR: (name: string) => `${name} 代码补全提示生成失败`,
  IMPORT_URL_ERROR: (url: string) => `${url} 脚本安装失败`,
  NAME_COLLISION_ERROR: (accessors: string) => `发现重名：${accessors}`,
};

// Business Edition upgrade page
export const MOVE_TO_BUSINESS_EDITION = (trailingChar: string) =>
  `升级到商业版本${trailingChar ? trailingChar : ""}`;

//Datasource environment
export const START_SWITCH_ENVIRONMENT = (environment: string) =>
  `将你的环境切换到 ${environment.toLowerCase()}，并且重新初始化页面`;
export const SWITCH_ENVIRONMENT_SUCCESS = (environment: string) =>
  `环境已经成功切换到 ${environment.toLowerCase()}`;
export const SWITCH_ENV_DISABLED_TOOLTIP_TEXT = (): string =>
  "体验数据源环境，请试试我们的";

export const TEST_DATASOURCE_SUCCESS = (
  datasourceName: string,
  environmentName: string,
) => {
  return environmentName
    ? `测试成功，${datasourceName} ${environmentName.toLowerCase()} 环境配置成功`
    : `测试成功，${datasourceName} 配置成功`;
};

export const TEST_DATASOURCE_ERROR = () => "测试失败，无法建立连接";

// Camera widget
export const DEFAULT_CAMERA_LABEL = () => "默认摄像头";
export const DEFAULT_CAMERA_LABEL_DESCRIPTION = () =>
  "用户的默认选择，对其他设备不适用";
export const FRONT_CAMERA_LABEL = () => "前置摄像头";
export const BACK_CAMERA_LABEL = () => "后置摄像头";

// Color picker
export const FULL_COLOR_PICKER_LABEL = () => "取色器";

// Column selector modal
export const EDIT_FIELDS = () => "编辑字段";
export const FIELDS_CONFIGURATION = () => "字段配置";
export const SAVE_CHANGES = () => "保存修改";
export const COLUMN_TYPE = () => "列类型";
export const COLUMN_NAME = () => "列名";
export const EDIT_FIELDS_DISABLED_TOOLTIP_TEXT = () => "选择表格编辑字段";

export const SAVE_CHANGES_DISABLED_TOOLTIP_TEXT = () =>
  "你必须至少选择一个字段保存";

export const NO_CONNECTABLE_WIDGET_FOUND = () =>
  "添加一个表格或者列表组件来承载数据";

export const CONNECT_BUTTON_TEXT = () => "连接数据";

export const NO_FIELDS_ADDED = () => "没有添加字段";

// One click binding control
export const DATASOURCE_DROPDOWN_OPTIONS = {
  CONNECT_TO_QUERY: () => "连接到查询",
  CONNECT_TO: () => "连接",
  CHOOSE_DATASOURCE_TO_CONNECT: () => "选择需要连接的数据源",
  CREATE_OR_EDIT_RECORDS: () => "创建或编辑数据",
  WRITE_JSON_SCHEMA: () => "修改 JSON schema",
  SELECT_A_DATASOURCE: () => "选择数据源",
  CONNECT_DATA: () => "连接数据",
  OTHER_ACTIONS: () => "其他操作",
};

export const COMMUNITY_TEMPLATES = {
  tabTitle: () => "案例",
  cancel: () => "取消",
  publishSuccessPage: {
    title: () => "发布成功",
    description: () =>
      "应用已经发布成功，成为社区的一个公开模板，现在其他用户可以体验、克隆你的杰作了~",
    viewTemplateButton: () => "查看模板",
  },
  publishFormPage: {
    title: () => "发布到社区",
    footer: {
      publishButton: () => "发布到社区",
      tnCText: () => "我已经了解发布应用后其他用户可以随意克隆",
    },
    preview: {
      thumbnail: () => "封面会自动生成",
    },
    templateForm: {
      titleInputLabel: () => "标题",
      titleInputPlaceholder: () => "模板标题",
      titleRequiredError: () => `请提供标题`,

      excerptInputLabel: () => "摘要",
      excerptInputPlaceholder: () => "一句话介绍",

      descriptionInputLabel: () => "描述",
      descriptionInputPlaceholder: () => "模板详情描述",

      useCasesInputLabel: () => "使用场景",
      useCasesInputPlaceholder: () => "选择使用场景",
    },
    authorDetails: {
      title: () => "作者信息",
      displayNameLabel: () => "昵称",
      displayNamePlaceholder: () => "昵称",
      nameRequiredError: () => `请提供昵称`,

      emailLabel: () => "邮箱",
      emailPlaceholder: () => "邮箱",
    },
    applicationSettings: {
      title: () => "应用设置",
      publicSetting: () => "公开应用",
      forkableSetting: () => "允许克隆",
    },
    publishedInfo: {
      title: () => "发布包含了什么内容？",
      correct: [
        () => "组件和他们的属性",
        () => "查询和JS对象",
        () => "数据源类型",
      ],
      incorrect: [
        () => "数据源校验信息",
        () => "API 校验信息",
        () => "环境变量",
        () => "Git 校验信息",
      ],
    },
    publishedFailedError: () => "发布失败",
  },
  modals: {
    unpublishedInfo: {
      title: () => "发布到 Appsmith 社区",
      description: () => "把应用发布到 Appsmith 社区让其他用户体验或者克隆复用",
      publishBtn: () => "开始发布",
    },
    publishedInfo: {
      title: () => "发布成功",
      description: () =>
        "应用已经发布成功，成为社区的一个公开模板，现在其他用户可以体验、克隆你的杰作了~",
      viewTemplate: () => "查看模板",
    },
  },
};

export const EMPTY_TABLE_TITLE_TEXT = () => "空表格";
export const EMPTY_TABLE_MESSAGE_TEXT = () => "暂无数据";
export const EMPTY_TABLE_SVG_ALT_TEXT = () => "空表格图片";
