export * from "ce/utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getHtmlPageTitle = (instanceName: string) => {
  return instanceName;
};

export const getPageTitle = (
  displayName?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  titleSuffix?: string,
) => {
  return `${displayName ? `${displayName} | ` : ""}${
    titleSuffix || "Appsmith"
  }`;
};
