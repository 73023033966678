export * from "ce/actions/tenantActions";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { UpdateCurrentTenantConfigRequest } from "@appsmith/api/TenantApi";

export const updateCurrentTenantConfig = (
  payload: UpdateCurrentTenantConfigRequest,
) => ({
  type: ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG,
  payload,
});
