export * from "ce/utils/permissionHelpers";
import {
  PERMISSION_TYPE as CE_PERMISSION_TYPE,
  isPermitted,
  LOGIC_FILTER,
} from "ce/utils/permissionHelpers";

export const PERMISSION_TYPE = {
  ...CE_PERMISSION_TYPE,
  /* Application permissions */
  DELETE_APPLICATION: "delete:applications",
  /* Datasource permissions */
  DELETE_WORKSPACE_DATASOURCES: "delete:workspaceDatasources",
};

export const hasDeleteApplicationPermission = (permissions: string[] = []) => {
  return isPermitted(
    permissions,
    [PERMISSION_TYPE.MANAGE_APPLICATION, PERMISSION_TYPE.DELETE_APPLICATION],
    LOGIC_FILTER.OR,
  );
};

export const hasCreateNewAppPermission = (permissions: string[] = []) => {
  return isPermitted(permissions, PERMISSION_TYPE.CREATE_APPLICATION);
};

export const hasDeleteWorkspacePermission = (permissions: string[] = []) => {
  return isPermitted(permissions, PERMISSION_TYPE.MANAGE_WORKSPACE);
};

export const hasCreateWorkspacePermission = (_permissions?: string[]) => true;

export const hasCreateDatasourcePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.CREATE_DATASOURCES);

export const hasManageDatasourcePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.MANAGE_DATASOURCES);

export const hasDeleteDatasourcePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.DELETE_DATASOURCES);

export const hasCreateDatasourceActionPermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.CREATE_DATASOURCE_ACTIONS);

export const hasCreatePagePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.CREATE_PAGES);

export const hasManagePagePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.MANAGE_PAGES);

export const hasDeletePagePermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.DELETE_PAGES);

export const hasCreateActionPermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.CREATE_ACTIONS);

export const hasManageActionPermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.MANAGE_ACTIONS);

export const hasDeleteActionPermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.DELETE_ACTIONS);

export const hasExecuteActionPermission = (_permissions?: string[]) =>
  isPermitted(_permissions, PERMISSION_TYPE.EXECUTE_ACTIONS);
