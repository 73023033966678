import React from "react";
import {
  SettingsHeader,
  SettingsSubHeader,
} from "pages/AdminSettings/Authentication/AuthPage";

const Banner = () => {
  return (
    <main>
      <SettingsHeader className="mt-1">自定义品牌</SettingsHeader>
      <SettingsSubHeader className="w-7/12 mt-1">
        为你的平台自定义 LOGO 和品牌色
      </SettingsSubHeader>
    </main>
  );
};

export default Banner;
